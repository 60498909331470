import React from 'react';

import useTheme from '@mui/styles/useTheme';
import CookieConsent from 'react-cookie-consent';

export function CookieBanner() {
  const { palette, shape } = useTheme();

  return (
    <CookieConsent
      location="bottom"
      buttonText="Hide this message"
      style={{
        backgroundColor: palette.primary.dark,
        color: palette.common.white,
        alignItems: 'center',
        padding: '0.5rem 1rem',
      }}
      buttonStyle={{
        backgroundColor: 'transparent',
        fontWeight: 'normal',
        color: palette.common.white,
        cursor: 'pointer',
        border: `1px solid ${palette.common.white}`,
        padding: '0.75rem 1rem',
        borderRadius: shape.borderRadius * 2,
      }}
      cookieName="analytics-consent"
      expires={365}>
      Some Feebris web portal functionality requires processing your data using third party
      services. We use: Intercom to provide in-product support, Sentry to track product errors, and
      PostHog to track feature usage and improve our service. For more information see our Privacy
      Notice on our website{' '}
      <a
        className="privacyNotice"
        href="https://feebris.com/privacy"
        target="_blank"
        rel="noreferrer">
        here
      </a>
      .
    </CookieConsent>
  );
}
