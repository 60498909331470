import React from 'react';
import { Button } from '@mui/material';
import EyeIcon from '@mui/icons-material/Visibility';
import OffEyeIcon from '@mui/icons-material/VisibilityOff';
import { gql } from '@apollo/client';
import { toast } from 'sonner';

import {
  PatientDetailsFragment,
  useUnwatchPatientMutation,
  useWatchPatientMutation,
} from '@/generated/graphql';

export const WATCH_PATIENT_MUTATION = gql`
  mutation WatchPatient($patientId: ID!) {
    addToWatchList(patientId: $patientId) {
      id
      onWatchList
    }
  }
`;

export const UNWATCH_PATIENT_MUTATION = gql`
  mutation UnwatchPatient($patientId: ID!) {
    removeFromWatchList(patientId: $patientId) {
      id
      onWatchList
    }
  }
`;

interface PatientWatchListButtonProps {
  patient: Pick<PatientDetailsFragment, 'firstName' | 'id' | 'onWatchList'>;
  refresh: () => void;
}

export const PatientWatchListButton = ({ patient, refresh }: PatientWatchListButtonProps) => {
  const [watchPatientMutation] = useWatchPatientMutation({
    variables: {
      patientId: patient.id,
    },
    // Optimistically update the patient's watch list status
    optimisticResponse: {
      __typename: 'Mutation',
      addToWatchList: {
        __typename: 'Patient',
        id: patient.id,
        onWatchList: true,
      },
    },
    onCompleted: () => {
      refresh();
      toast.success(`${patient.firstName} added to watch list`, {
        id: `change-watched-${patient.id}`,
        actionButtonStyle: {
          color: 'inherit',
          backgroundColor: 'transparent',
        },
        action: {
          label: 'Undo',
          onClick: async () => {
            await unwatchPatientMutation();
          },
        },
      });
    },
  });
  const [unwatchPatientMutation] = useUnwatchPatientMutation({
    variables: {
      patientId: patient.id,
    },
    // Optimistically update the patient's watch list status
    optimisticResponse: {
      __typename: 'Mutation',
      removeFromWatchList: {
        __typename: 'Patient',
        id: patient.id,
        onWatchList: false,
      },
    },
    onCompleted: () => {
      refresh();
      toast.success(`${patient.firstName} removed from watch list`, {
        id: `change-watched-${patient.id}`,
        actionButtonStyle: {
          color: 'inherit',
          backgroundColor: 'transparent',
        },
        action: {
          label: 'Undo',
          onClick: async () => {
            await watchPatientMutation();
          },
        },
      });
    },
  });

  return patient.onWatchList ? (
    <Button
      startIcon={<OffEyeIcon />}
      size="small"
      variant="outlined"
      color="primary"
      onClick={async () => await unwatchPatientMutation()}>
      Unwatch
    </Button>
  ) : (
    <Button
      startIcon={<EyeIcon />}
      size="small"
      variant="outlined"
      color="primary"
      onClick={async () => await watchPatientMutation()}>
      Watch
    </Button>
  );
};
