import React, { useMemo } from 'react';

import { Box, Chip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { softSignDisplay } from '@/helpers/questionnaire';
import { SoftSign } from '@/generated/graphql';

export interface SoftSignsChipsProps {
  softSigns: Pick<SoftSign, 'key' | 'isEmergency'>[];
  showHeader?: boolean;
}

export function SoftSignsChips({ softSigns, showHeader = true }: SoftSignsChipsProps) {
  const classes = useStyles();

  const softSignData = useMemo(
    () =>
      softSigns
        .filter((softSign) => Object.keys(softSignDisplay).includes(softSign.key))
        .map((softSign) => ({
          text: softSignDisplay[softSign.key as keyof typeof softSignDisplay],
          isEmergency: softSign.isEmergency,
        }))
        .sort((a, b) => (a.isEmergency > b.isEmergency ? -1 : 1)),
    [softSigns],
  );

  if (!softSignData.length) {
    return null;
  }

  return (
    <div>
      {showHeader && <Typography className={classes.label}>Soft Signs</Typography>}
      <Box marginTop={0.75}>
        {softSignData.map((softSign) => (
          <Chip
            data-testid="soft-sign-chip"
            key={softSign.text}
            label={softSign.text}
            sx={{
              marginBottom: 1,
              marginRight: 1,
            }}
            color={softSign.isEmergency ? 'error' : 'warning'}
          />
        ))}
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
  },
}));

export const generateSoftSignsData = (softSigns: Pick<SoftSign, 'key' | 'isEmergency'>[]) => {
  return softSigns;
};
