import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toast } from 'sonner';

import auth from '@/controllers/Auth';
import { useUpdateMeMutation } from '@/generated/graphql';

export default function UserProfile() {
  const classes = useStyles();
  return (
    <Box maxWidth="1024px" alignSelf="left" marginBottom={2}>
      <Paper elevation={1} className={classes.container}>
        <Typography variant="h6" gutterBottom>
          Your Profile
        </Typography>

        <UserProfileForm />
      </Paper>
    </Box>
  );
}

function UserProfileForm() {
  const classes = useStyles();
  const [dirty, setDirty] = useState(false);
  const [firstName, setFirstName] = useState<string>(auth.me('firstName', '') ?? '');
  const [lastName, setLastName] = useState<string>(auth.me('lastName', '') ?? '');
  const [jobRole, setJobRole] = useState<string>(auth.me('jobRole', '') ?? '');

  const [updateMeMutation, { loading }] = useUpdateMeMutation({
    onError: () => toast.error('An error occurred when trying to update your profile'),
    onCompleted: async () => {
      setDirty(false);
      await auth.refreshMeData();
      toast.success('Your profile has been updated');
    },
  });

  function save() {
    updateMeMutation({
      variables: {
        user: {
          firstName: firstName.trim().length > 0 ? firstName : null,
          lastName: lastName.trim().length > 0 ? lastName : null,
          jobRole: jobRole.trim().length > 0 ? jobRole : null,
        },
      },
    });
  }

  function cancel() {
    setFirstName(auth.me('firstName', ''));
    setLastName(auth.me('lastName', ''));
    setJobRole(auth.me('jobRole', ''));
    setDirty(false);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          variant="filled"
          label="First name"
          name="firstName"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
            setDirty(true);
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Last name"
          variant="filled"
          name="lastName"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
            setDirty(true);
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Job Role"
          variant="filled"
          name="jobRole"
          value={jobRole}
          onChange={(e) => {
            setJobRole(e.target.value);
            setDirty(true);
          }}
          placeholder="e.g. Staff Nurse, Dietitian, etc"
          helperText="The role you take in your ordinary work as part of your organisation. This applies to your acting organisation only."
          fullWidth
        />
      </Grid>
      <Grid item xs={12} style={{ display: dirty ? 'block' : 'none' }}>
        <div className={classes.formActions}>
          <Button onClick={cancel} disabled={loading} className="e2e__UserProfileForm_cancel">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={save}
            disabled={loading}
            className="e2e__UserProfileForm_submit"
            role="button">
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export const MUTATION_UPDATE_ME = gql`
  mutation UpdateMe($user: MeInput!) {
    updateMe(user: $user)
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  formActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
}));
