import React from 'react';

import { pdf } from '@react-pdf/renderer';
import { gql } from '@apollo/client';

import fileSaver from 'file-saver';
import { toast } from 'sonner';
import { format } from 'date-fns';

import { usePatientReportDataLazyQuery } from '@/generated/graphql';

import { PatientReportDocument } from './PatientReportDocument';

export const PATIENT_REPORT_PATIENT_DATA_FRAGMENT = gql`
  fragment PatientReportPatientData on Patient {
    id
    createdAt
    firstName
    lastName
    birthDate
    gender
    address {
      address
    }
    nhsNumberResponseDetails {
      nhsNumber
    }
    organizations {
      address {
        address
      }
    }
  }
`;

export const PATIENT_WARD_ADMISSION_FRAGMENT = gql`
  fragment PatientReportAdmissionData on WardAdmission {
    admittedAt
    ward {
      name
      organization {
        id
        name
      }
    }
    carePathway {
      name
    }
  }
`;

export const ADMISSION_EVENT_OVERVIEW_FRAGMENT = gql`
  fragment PatientReportAdmissionEventData on AdmissionEvent {
    admissionId
    createdAt
    createdBy {
      ...FormattableUser
    }
    ward {
      name
      organization {
        id
        name
      }
    }
    carePathway {
      name
    }
    WardId
    type
  }
`;

export const QUERY_PATIENT_HAS_DISCHARGE_DATA_GET_CHECKUPS_FRAGMENT = gql`
  fragment PatientReportCheckupData on Checkup {
    id
    createdAt
    startedAt
    glucose
    ewsScores {
      BPScore
      consciousnessScore
      HRScore
      RRScore
      SpO2Score
      tempScore
      totalScore
      riskLevel
    }
    respiratoryRate {
      value
      source
    }
    bloodPressureData
    pulseRate {
      value
      source
      isManual
    }
    temperature
    weight
    notes {
      text
    }
    isStable
    questionnaire
    softSigns {
      key
      isEmergency
    }
    selectedAction
    lungSounds {
      url
    }
    pulseOxiData
    consciousness
    createdBy {
      ...FormattableUser
    }
    organization {
      id
      name
    }
  }
`;

export const PATIENT_ALERTS_FRAGMENT = gql`
  fragment PatientReportAlertsData on Alert {
    id
    startedAt
    alertRule {
      name
      description
    }
  }
`;

export const PATIENT_NOTES_FRAGMENT = gql`
  fragment PatientReportNotesData on PatientNote {
    id
    text
    createdAt
    createdBy {
      ...FormattableUser
    }
    organization {
      id
      name
    }
  }
`;

export const PATIENT_REPORT_VITALS_AGGREGATE_FRAGMENT = gql`
  fragment PatientReportVitalsAggregate on VitalsSummaryFrame {
    start
    end
    values {
      pulseRate {
        median
        min
        max
      }
      respiratoryRate {
        median
        min
        max
      }
      systolicBloodPressure {
        median
        min
        max
      }
      diastolicBloodPressure {
        median
        min
        max
      }
      spO2 {
        median
        min
        max
      }
      temperature {
        median
        min
        max
      }
      weight {
        median
        min
        max
      }
      glucose {
        median
        min
        max
      }
    }
  }
`;

export const PATIENT_REPORT_SOFT_SIGNS_AGGREGATE_FRAGMENT = gql`
  fragment PatientReportSoftSignsAggregate on SoftSignsSummaryFrame {
    start
    end
    values {
      softSign {
        key
        isEmergency
      }
      count
    }
  }
`;

export const QUERY_PATIENT_REPORT_DATA_FETCH = gql`
  query PatientReportData(
    $patientId: ID!
    $startDate: Date!
    $endDate: Date!
    $aggregateIntervalHours: Int
    $queryAggregates: Boolean!
    $querySoftSignsAggregates: Boolean!
    $queryCheckups: Boolean!
    $queryNotes: Boolean!
    $queryAlerts: Boolean!
    $queryWardAdmissions: Boolean!
  ) {
    patient(id: $patientId) {
      ...PatientReportPatientData
      admissionEvents @include(if: $queryWardAdmissions) {
        ...PatientReportAdmissionEventData
      }
      wardAdmission @include(if: $queryWardAdmissions) {
        ...PatientReportAdmissionData
      }
      notes(startDate: $startDate, endDate: $endDate) @include(if: $queryNotes) {
        ...PatientReportNotesData
      }
      vitalsSummary(
        startDate: $startDate
        endDate: $endDate
        intervalHours: $aggregateIntervalHours
      ) @include(if: $queryAggregates) {
        frames {
          ...PatientReportVitalsAggregate
        }
      }
      softSignsSummary(startDate: $startDate, endDate: $endDate, intervalHours: 24)
        @include(if: $querySoftSignsAggregates) {
        frames {
          ...PatientReportSoftSignsAggregate
        }
      }
    }
    # TODO: This should be part of the "patient" graph within the query above
    checkupsByPatient(patientId: $patientId, startDate: $startDate, endDate: $endDate)
      @include(if: $queryCheckups) {
      ...PatientReportCheckupData
    }
    # TODO: This should be part of the "patient" graph within the query above
    alerts(patientId: $patientId, startDate: $startDate, endDate: $endDate, type: "AlertRule")
      @include(if: $queryAlerts) {
      ...PatientReportAlertsData
    }
  }
`;

interface UseDownloadPatientDischargePDFProps {
  patientId: string;
}

export enum ReportSection {
  VitalsAggregates = 'vitalsSummary',
  SoftSignsAggregates = 'softSignsSummary',
  Checkups = 'checkups',
  Notes = 'notes',
  Alerts = 'alerts',
  WardAdmissions = 'wardAdmissions',
}

const DEFAULT_REPORT_SECTIONS: Array<ReportSection> = [
  ReportSection.VitalsAggregates,
  ReportSection.SoftSignsAggregates,
  ReportSection.Notes,
  ReportSection.Alerts,
  ReportSection.WardAdmissions,
  ReportSection.Checkups,
];

interface UseDownloadPatientDischargePDFOptions {
  startDate: Date;
  endDate: Date;
  aggregateIntervalHours?: number | null;
  sections?: Array<ReportSection>;
}

const santitizeNameForPath = (name: string) =>
  name
    .replace(/[^\w\s.-]/g, '') // Remove special characters except space, period, and hyphen
    .normalize('NFD') // Normalize to decomposed form (accented characters to non-accented)
    .replace(/[\u0300-\u036f]/g, '') // Remove combining diacritical marks
    .replace(/\s+/g, '_') // Replace spaces with underscores
    .toLowerCase();

export function useDownloadPatientReport({ patientId }: UseDownloadPatientDischargePDFProps) {
  const handleError = () => toast.error('An error occurred when downloading the patient report');

  const [fetch, { loading, error }] = usePatientReportDataLazyQuery({
    onError: handleError,
    notifyOnNetworkStatusChange: false,
  });

  const downloadPdf = async ({
    startDate,
    endDate,
    aggregateIntervalHours = null,
    sections = DEFAULT_REPORT_SECTIONS,
  }: UseDownloadPatientDischargePDFOptions) => {
    const response = await fetch({
      variables: {
        patientId,
        aggregateIntervalHours,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        queryAggregates: sections.includes(ReportSection.VitalsAggregates),
        querySoftSignsAggregates: sections.includes(ReportSection.SoftSignsAggregates),
        queryCheckups: sections.includes(ReportSection.Checkups),
        queryNotes: sections.includes(ReportSection.Notes),
        queryAlerts: sections.includes(ReportSection.Alerts),
        queryWardAdmissions: sections.includes(ReportSection.WardAdmissions),
      },
    });

    if (!response.data) {
      handleError();
      return;
    }

    try {
      const res = await pdf(
        <PatientReportDocument
          patient={response.data.patient}
          checkups={response.data.checkupsByPatient}
          currentWardAdmission={response.data.patient.wardAdmission}
          admissionEvents={response.data.patient.admissionEvents}
          notes={response.data.patient.notes}
          alerts={response.data.alerts}
          vitalsSummary={response.data.patient.vitalsSummary?.frames}
          softSignsSummary={response.data.patient.softSignsSummary?.frames}
          reportFrom={startDate}
          reportTo={endDate}
        />,
      ).toBlob();

      const fileName = `${santitizeNameForPath(
        response.data.patient.firstName,
      )}-${santitizeNameForPath(response.data.patient.lastName)}_patient-report_${format(
        startDate,
        'yyyy-MM-dd',
      )}_${format(endDate, 'yyyy-MM-dd')}.pdf`;

      fileSaver.saveAs(res, fileName);

      toast.success('Patient report downloaded');
    } catch (error) {
      handleError();
    }
  };

  return {
    downloadPdf,
    loading,
    error,
  };
}
