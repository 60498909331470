import React from 'react';
import _ from 'lodash';
import { differenceInMonths, differenceInYears, format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Link, Button } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';

import LocaleFormatting from '@/helpers/LocaleFormatting';
import placeHolderProfilePhoto from './images/profile.png';
import { SimilarNamesWarning } from '@/components/SimilarNamesWarning';
import NhsNumber from './NhsNumber';
import { CheckupDetailsFragment, PatientDetailsFragment } from '@/generated/graphql';
import { PatientHeaderActionsDropdown } from '@/components/PatientHeaderActionsDropdown';
import { usePatientDischargeModal } from './patientDischarge';
import { isDefined } from '@/helpers/isDefined';
import { useMeActingOrganizationFeature, useMePermission } from '@/hooks/useAuth';
import { UserName } from '@/components/UserName';
import auth from '@/controllers/Auth';
import { usePatientAdmitJourney } from './Journeys/AdmitPatientJourney';
import { PatientWatchListButton } from './PatientWatchListButton';

interface PatientHeaderProps {
  patient?: PatientDetailsFragment | null;
  checkup?: CheckupDetailsFragment | null;
  refresh: () => void;
}

export default function PatientHeader({ patient, checkup, refresh }: PatientHeaderProps) {
  const shouldHideLatestCheckup = checkup != null;
  const classes = useStyles();
  const canAdmitWardPatients = useMePermission('admit_ward_patients');
  const hasNhsNumberRetrieval = useMeActingOrganizationFeature('nhsNumberRetrieval');

  const { showPatientDischargeModal } = usePatientDischargeModal({
    onPatientDischarged: () => refresh(),
  });

  const { startJourney: startAdmitJourney } = usePatientAdmitJourney({
    onClose: () => refresh(),
    showPatientRecordLink: false, // We're already on the patient record here
  });

  const currentAdmission = patient?.wardAdmission;

  if (!patient) {
    return (
      <div className={classes.mainContainer}>
        <div className={classes.imageContainer}>
          <img alt="User Profile" src={placeHolderProfilePhoto} />
        </div>
        <div className={classes.detailsContainer}>
          <Skeleton height={40} width={400} />
          <Skeleton height={20} width={200} />
          <Skeleton height={20} width={300} />
          <Skeleton height={20} width={250} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.mainContainer}>
      <Stack direction="row">
        <div className={classes.imageContainer}>
          <img alt="User Profile" src={placeHolderProfilePhoto} />
        </div>

        <div className={classes.detailsContainer}>
          <div className={classes.personalInfoContainer}>
            <SimilarNamesWarning patient={patient}>
              <Link
                to={`/patient/${patient.id}`}
                component={RouterLink}
                className={classes.name}
                color="textPrimary">
                <div>{`${patient.firstName} ${patient.lastName}`}</div>
              </Link>
            </SimilarNamesWarning>
            <Stack direction="row" alignItems="center" flexWrap="wrap" gap={0.5} marginY={0.5}>
              <Stack direction="row" alignItems="center">
                <div className={classes.separator}>·</div>
                <HeaderGenderAndAge className={classes.genderAndAge} patient={patient} />
              </Stack>
              {patient.address && (
                <Stack direction="row" alignItems="center">
                  <div className={classes.separator}>·</div>
                  <div
                    className={
                      classes.contactDetails
                    }>{`${patient.address.address} ${patient.address.postcode}`}</div>
                </Stack>
              )}
              {patient.telephone && (
                <div className={classes.telephoneContainer}>
                  <div className={classes.separator}>·</div>
                  <div className={classes.contactDetails}>{`${patient.telephone}`}</div>
                </div>
              )}
              {patient.selfCare?.email && (
                <div className={classes.emailContainer}>
                  <div className={classes.separator}>·</div>
                  <div className={classes.contactDetails}>{patient.selfCare?.email}</div>
                </div>
              )}
            </Stack>
          </div>
          {!shouldHideLatestCheckup && patient.latestCheckup?.endedAt && (
            <div>
              <span className={classes.label}>Latest Check-up: </span>
              {LocaleFormatting.formatCheckupTimeLongWithoutWeekDay(patient.latestCheckup.endedAt)}
            </div>
          )}
          {hasNhsNumberRetrieval && (
            <div className={classes.nhsNumber}>
              <div className={classes.nhsNumberLabel}>NHS Number:</div>
              <div>
                <NhsNumber
                  patientId={patient.id}
                  nhsNumber={patient.nhsNumberResponseDetails?.nhsNumber}
                />
              </div>
            </div>
          )}
          {patient.wardAdmission && (
            <div>
              <span className={classes.label}>Ward:</span> {patient.wardAdmission.ward.name} -
              Admitted{' '}
              {LocaleFormatting.formatCheckupTimeLongWithoutWeekDay(
                patient.wardAdmission.admittedAt,
              )}
            </div>
          )}
          {patient.wardAdmission?.carePathway?.name && (
            <div>
              <span className={classes.label}>Care Pathway:</span>{' '}
              {patient.wardAdmission.carePathway.name}{' '}
            </div>
          )}
          {patient.latestContinuousMonitoring &&
            patient.latestContinuousMonitoring.continuousMonitoringSession.organization.id ===
              auth.me('actingOrganization.id') &&
            !patient.latestContinuousMonitoring.continuousMonitoringSession.endedAt && (
              <div>
                <span className={classes.label}>Continuous Monitoring Kit:</span>{' '}
                {patient.latestContinuousMonitoring?.continuousMonitoringSession.vivalinkSubjectId}
              </div>
            )}
          {patient.activityMonitoringSession &&
            patient.activityMonitoringSession.organization.id ===
              auth.me('actingOrganization.id') && (
              <div>
                <span className={classes.label}>Activity Monitoring Kit:</span>{' '}
                {patient.activityMonitoringSession?.pacsanaUserName}
              </div>
            )}
          {checkup?.endedAt && (
            <div className={classes.label}>
              <div className={classes.createdAtAndCreatedBy}>
                {LocaleFormatting.formatCheckupTimeLongWithoutWeekDay(checkup.endedAt)}
                {checkup.createdBy ? (
                  <>
                    {' '}
                    by{' '}
                    <UserName
                      user={checkup.createdBy}
                      userActingOrganization={checkup.organization}
                    />
                  </>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </Stack>
      <div className={classes.spaceExpander}></div>

      <div className={classes.patientActions}>
        <PatientWatchListButton patient={patient} refresh={refresh} />

        {canAdmitWardPatients && (
          <>
            {isDefined(currentAdmission) ? (
              <Button
                startIcon={<AssignmentIndIcon />}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => showPatientDischargeModal({ patient, currentAdmission })}>
                Discharge from Ward
              </Button>
            ) : (
              <Button
                startIcon={<AssignmentIndIcon />}
                size="small"
                variant="contained"
                color="primary"
                onClick={() =>
                  startAdmitJourney({
                    initialJourneyState: {
                      patient: patient,
                      initializedWithPatient: true,
                    },
                  })
                }>
                Admit to ward
              </Button>
            )}
          </>
        )}
        <PatientHeaderActionsDropdown patient={patient} refresh={refresh} />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: theme.typography.body2.fontSize,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    borderBottom: '1px solid rgba(0,0,0,0.1)',
  },
  imageContainer: {
    display: 'none',
    '& img': {
      width: theme.spacing(8),
      height: theme.spacing(8),
      borderRadius: '50%',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
  },
  personalInfoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    fontSize: '30px',
    lineHeight: '0.5',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  name: {
    fontSize: '24px',
  },
  genderAndAge: {
    color: 'gray',
    fontWeight: 'bold',
  },
  organization: {
    color: 'gray',
    fontWeight: 'bold',
  },
  contactDetails: {
    color: 'gray',
  },
  telephoneContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  emailContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  nhsNumberLabel: {
    marginRight: theme.spacing(0.5),
    fontWeight: 500,
  },
  label: {
    fontWeight: 500,
  },
  createdAtAndCreatedBy: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  nhsNumber: {
    display: 'flex',
    alignItems: 'center',
  },
  spaceExpander: {
    flexGrow: 1,
  },
  patientActions: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    flexDirection: 'row',
    margin: theme.spacing(1, 0.5, 0.5, 0),
    // add left margin to all buttons except the first one
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function HeaderGenderAndAge({
  className,
  patient: { gender, birthDate },
}: {
  className: string;
  patient: PatientDetailsFragment;
}) {
  const formattedGender = _.capitalize(gender);
  const birthDateObject = new Date(birthDate);
  const formattedBirthDate = format(birthDateObject, 'dd/MM/yyyy');
  const ageYears = differenceInYears(new Date(), birthDateObject);
  const ageMonths = differenceInMonths(new Date(), birthDateObject);
  const formattedAge = isNaN(ageYears) || ageYears < 1 ? `${ageMonths} m.o.` : `${ageYears} y.o.`;

  return (
    <div className={className}>{`${formattedGender}, ${formattedBirthDate} (${formattedAge})`}</div>
  );
}
