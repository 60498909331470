import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CheckupDetails as CheckupDetailsClassBased } from './components/CheckupDetails';
import {
  CheckupDetailsFragment,
  PatientDetailsFragment,
  useGetCheckupDetailsQuery,
  useGetPatientDetailsQuery,
} from '@/generated/graphql';
import { gql } from '@apollo/client';

export const GET_CHECKUP_DETAILS = gql`
  fragment CheckupDetails on Checkup {
    __typename
    id
    temperature
    ewsScores {
      __typename
      BPScore
      consciousnessScore
      HRScore
      riskLevel
      BPScore
      RRScore
      SpO2Score
      tempScore
      totalScore
    }
    subtype
    notes {
      __typename
      id
      text
      patient {
        id
      }
      createdBy {
        id
      }
      createdAt
      updatedAt
      checkup {
        id
      }
    }
    pulseRate {
      __typename
      isManual
      source
      value
    }
    pulseOxiData
    respiratoryRate {
      __typename
      value
      source
    }
    consciousness
    bloodPressureData
    questionnaire
    softSigns {
      key
      isEmergency
    }
    weight
    glucose
    picture {
      __typename
      url
      caption
    }
    lungSounds {
      __typename
      url
    }
    endedAt
    createdBy {
      __typename
      ...FormattableUser
    }
    organization {
      id
      name
    }
  }

  query GetCheckupDetails($checkupId: ID!) {
    checkup(id: $checkupId) {
      ...CheckupDetails
    }
  }
`;

interface Props
  extends WithTranslation,
    RouteComponentProps<{ patientId: string; checkupId: string }> {}

/*
 * The original CheckupDetails component ignored one-way data flow and other concepts of React.
 * By using this wrapper component, we can gradually migrate existing components as and when we need to
 * without modifying the original components.
 */
function CheckupDetails({
  match: {
    params: { patientId, checkupId },
  },
  history: { push },
  ...rest
}: Props) {
  const { refetch, error, checkup, patient } = useCheckupDetailsPage({
    patientId,
    checkupId,
  });
  return (
    <CheckupDetailsClassBased
      {...rest}
      patientId={patientId}
      checkupId={checkupId}
      navigateToPatient={() => void push(`/patient/${patientId}`)}
      refetch={refetch}
      error={error}
      checkup={checkup}
      patient={patient}
    />
  );
}

function useCheckupDetailsPage({
  patientId,
  checkupId,
}: {
  patientId: string;
  checkupId: string;
}): {
  refetch: () => Promise<{
    // TODO: make this return Promise<void> and use the props to pass this data rather than return value of this function
    patient: null | PatientDetailsFragment;
    checkup: null | CheckupDetailsFragment;
  }>;
  error: { checkup?: Error; patient?: Error };
  checkup: null | CheckupDetailsFragment;
  patient: null | PatientDetailsFragment;
} {
  const {
    refetch: refetchPatient,
    error: errorPatient,
    data: patientData,
  } = useGetPatientDetailsQuery({
    variables: {
      patientId,
    },
  });
  const {
    refetch: refetchCheckup,
    error: errorCheckup,
    data: checkupData,
  } = useGetCheckupDetailsQuery({
    variables: {
      checkupId,
    },
  });

  return useMemo(
    () => ({
      refetch: async () => {
        const [patientResult, checkupResult] = await Promise.allSettled([
          refetchPatient(),
          refetchCheckup(),
        ] as const);

        return {
          patient:
            (patientResult.status === 'fulfilled' && patientResult.value.data?.patient) || null,
          checkup:
            (checkupResult.status === 'fulfilled' && checkupResult.value.data?.checkup) || null,
        };
      },
      error: {
        checkup: errorCheckup,
        patient: errorPatient,
      },
      checkup: checkupData?.checkup ?? null,
      patient: patientData?.patient ?? null,
    }),
    [
      checkupData?.checkup,
      errorCheckup,
      errorPatient,
      patientData?.patient,
      refetchCheckup,
      refetchPatient,
    ],
  );
}

export default withTranslation()(withRouter(CheckupDetails));
