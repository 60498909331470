import auth from '@/controllers/Auth';
import { useCallback, useEffect, useState } from 'react';

// TODO: This should be moved to a shared location (probably typing the GQL schema properly)
export interface OrganizationFeatureFlags {
  nhsNumberRetrieval: boolean;
  stethoscopeQualityEnabled: boolean;
  skipConsent: boolean;
  selfCare: boolean;
  skipNextAction: boolean;
  automaticLogoutAlert: number;
  wards: boolean;
  // TODO: FEP-3041, Once all organizations have wards, this feature flag should be removed.
  /**
   * Show all patients when no wards are selected
   * This is a temporary feature flag to allow us to show all patients when no wards are selected.
   */
  virtualWardAllPatientsLegacyBehavior: boolean;
  checkupSchedule: boolean;
  continuousMonitoring: boolean;
  activityMonitoring: boolean;
  ehrIntegrations: boolean;
  integrationApi: boolean;
  cleoIntegrationApi: boolean;
  continuousMonitoringSpo2FromMax: boolean;
  videoCall: boolean;
  newPulseOxScreen: boolean;
}

export function useAuthMe<T = unknown>(path: string): T | undefined;
export function useAuthMe<T = unknown>(path: string, defaultValue: T): T;

/**
 * This hook is used to fetch the current users auth data.
 *
 * Since the auth data is stored in localStorage, outside of the react lifecycle and state,
 * we need to use this hook to listen for changes to the localStorage and update the state accordingly.
 *
 * This allows us to re-render / run logic when the auth data changes.
 */
export function useAuthMe<T = unknown>(path: string, defaultValue?: T): T | undefined {
  const [value, setValue] = useState(auth.me(path, defaultValue));

  const storageListener = useCallback(
    (event: StorageEvent) => {
      if (event.key === 'me') {
        setValue(auth.me(path, defaultValue));
      }
    },
    [path, defaultValue],
  );

  useEffect(() => {
    window.addEventListener('storage', storageListener);

    // clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', storageListener);
    };
  }, [storageListener]);

  return value;
}

export function useMeActingOrganizationFeature<K extends keyof OrganizationFeatureFlags>(
  feature: K,
): OrganizationFeatureFlags[K] | undefined;
export function useMeActingOrganizationFeature<K extends keyof OrganizationFeatureFlags>(
  feature: K,
  defaultValue: OrganizationFeatureFlags[K],
): OrganizationFeatureFlags[K];

/**
 * Fetch a feature flag from the current users auth data.
 */
export function useMeActingOrganizationFeature<K extends keyof OrganizationFeatureFlags>(
  feature: K,
  defaultValue?: OrganizationFeatureFlags[K],
): OrganizationFeatureFlags[K] | undefined {
  return useAuthMe(`actingOrganization.features.${feature}`, defaultValue);
}

/**
 * Fetch all feature flags from the current users auth data.
 */
export const useMeActingOrganizationFeatures = (): OrganizationFeatureFlags | undefined => {
  return useAuthMe(`actingOrganization.features`);
};

/**
 * Check if the current user has a permission, based on their current auth data.
 */
export const useMePermission = (permission: string): boolean => {
  return useAuthMe(`permissions.${permission}`, false);
};

/**
 * Fetch all permissions for the current user, based on their current auth data.
 */
export const useMePermissions = () => {
  return useAuthMe<Record<string, boolean>>('permissions', {});
};
