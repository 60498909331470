import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import { Formik } from 'formik';
import auth from '@/controllers/Auth';
import clsx from 'clsx';
import { FlattenedUser } from '../helpers/flattenUserOrganization';
import { toast } from 'sonner';
import { gql } from '@apollo/client';
import { useUpdateUserOrganizationMutation } from '@/generated/graphql';

interface UserFormModalProps {
  open: boolean;
  onClose: () => void;
  user: FlattenedUser;
}

export default function UserFormModal({ onClose, open, user }: UserFormModalProps) {
  const handleSubmit = useEditUser(user.id, onClose);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth>
      <UserForm
        userId={user.id}
        onCancel={onClose}
        onSubmit={handleSubmit}
        initialValues={{
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            jobRole: user.jobRole,
          },
          accessLevel: user.roles[0],
        }}
      />
    </Dialog>
  );
}

interface UserFormValues {
  user: {
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    email: Maybe<string>;
    jobRole: Maybe<string>;
  };
  accessLevel: Maybe<string>;
}

// type T, except no property can be null or undefined
type RecursiveNotMaybe<T> = {
  [K in keyof T]: T[K] extends Record<never, unknown> ? RecursiveNotMaybe<T[K]> : NotMaybe<T[K]>;
};

type ValidatedUserFormValues = RecursiveNotMaybe<UserFormValues>;

function UserForm({
  onCancel,
  userId,
  initialValues,
  onSubmit,
}: {
  onCancel: () => void;
  userId: string | undefined;
  initialValues: UserFormValues;
  onSubmit: (validatedData: ValidatedUserFormValues) => Promise<void>;
}) {
  const classes = useStyles();
  const formTitle = userId ? 'Edit User' : 'Add User';
  const submitButtonTitle = userId ? 'Update User' : 'Add User';

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true);
        try {
          await onSubmit(data as ValidatedUserFormValues);
        } finally {
          setSubmitting(false);
        }
      }}>
      {({ errors, values, isSubmitting, handleChange, handleBlur, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">{formTitle}</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="user.email"
                    label="Email"
                    type="email"
                    defaultValue={values.user.email}
                    className={classes.textField}
                    value={values.user.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{ readOnly: true }}
                    error={_.has(errors, 'user.email')}
                    helperText={_.get(errors, 'user.email')}
                    inputProps={{ tabIndex: 3 }}
                    required
                    variant="filled"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    className={clsx(classes.textField, 'e2e__accesslevel')}
                    error={_.has(errors, 'user.roles')}
                    variant="filled">
                    <InputLabel>Access Level</InputLabel>
                    <Select
                      fullWidth
                      name="accessLevel"
                      value={values.accessLevel}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ tabIndex: 4 }}>
                      {auth.me('actingOrganization.roles', []).map(
                        (role: { isSystemRole: boolean; name: string; description: string }) =>
                          !role.isSystemRole && (
                            <MenuItem key={role.name} value={role.name}>
                              <Box className={classes.roleMenuItem}>
                                <Typography variant="body1">{role.name}</Typography>
                                <Typography variant="caption">{role.description}</Typography>
                              </Box>
                            </MenuItem>
                          ),
                      )}
                    </Select>
                    <FormHelperText>{_.get(errors, 'user.roles')}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="user.jobRole"
                    label="Job Role"
                    type="text"
                    defaultValue={values.user.jobRole}
                    className={classes.textField}
                    value={values.user.jobRole}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={_.has(errors, 'user.jobRole')}
                    placeholder="e.g. Staff Nurse, Dietitian, etc"
                    helperText="The role this user takes in their ordinary work as part of your organisation."
                    inputProps={{ tabIndex: 3 }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel}>Cancel</Button>
              <Button
                className="e2e__userformsubmitbtn"
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}>
                {submitButtonTitle}
              </Button>
            </DialogActions>
          </form>
        );
      }}
    </Formik>
  );
}

const useStyles = makeStyles((theme) => ({
  textField: {
    display: 'block',
  },
  roleMenuItem: {
    flexDirection: 'column',
    padding: theme.spacing(0.5, 1.5),
  },
}));

export const UPDATE_USER_ORGANIZATION = gql`
  mutation UpdateUserOrganization($userId: ID!, $roles: [String!]!, $jobRole: String) {
    updateUserOrganization(userId: $userId, roles: $roles, jobRole: $jobRole) {
      user {
        id
      }
    }
  }
`;

const useEditUser = (userId: string, onSuccess: () => void) => {
  const [updateUserOrganizationMutation] = useUpdateUserOrganizationMutation();

  return async (formValues: ValidatedUserFormValues) => {
    const jobRole = formValues.user.jobRole?.trim();

    try {
      await updateUserOrganizationMutation({
        variables: {
          userId: userId,
          roles: formValues.accessLevel,
          jobRole: jobRole,
        },
      });

      toast.success('User saved');
      onSuccess();
    } catch (e) {
      toast.error('An error occurred while updating user');
      throw e;
    }
  };
};
