// Don't worry about strict types until we migrate this to use GQL code generation where we get the types for free
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Loading from '@/components/Loading';
import CheckupSummaryTable from './CheckupSummaryTable';
import VitalSignChart from './VitalSignChart';
import EditSaveThresholdsSelector from './EditSaveThresholdsSelector';
import ContinuousMonitoringSummaryTable from './ContinuousMonitoringSummaryTable';
import api from '@/controllers/Api';
import auth from '@/controllers/Auth';
// @ts-expect-error need to convert to typescript
import { DEFAULT_EWS_THRESHOLDS } from 'feebris-news-calculator';
import LocaleFormatting, { feebrisFormatter } from '@/helpers/LocaleFormatting';
import { calculateEWSScores } from '@/helpers/Checkup';
import moment from 'moment';
import { gql } from '@apollo/client';
import { toast } from 'sonner';

import { COLOR_BANDS, GRAY_COLOR_BANDS } from '@/styles/NEWSColors';
import {
  FormattableUserFragment,
  PatientCheckupsTableItemFragment,
  PatientDetailsFragment,
  useGetPatientCheckupsQuery,
} from '@/generated/graphql';
import { ActivityMonitoringSummaryTable } from './ActivityMonitoringSummaryTable';
import Explainer from '@/components/Explainer';
import { UserName } from '@/components/UserName';

export const GET_PATIENT_CHECKUPS = gql`
  fragment PatientCheckupsTableItem on Checkup {
    id
    endedAt
    type
    # TODO: remove subtype
    subtype
    respiratoryRate {
      value
      source
    }
    pulseOxiData
    bloodPressureData
    pulseRate {
      value
    }
    temperature
    consciousness
    ewsScores {
      BPScore
      consciousnessScore
      HRScore
      RRScore
      SpO2Score
      tempScore
      totalScore
      riskLevel
    }
    questionnaire
    softSigns {
      key
      isEmergency
    }
    selectedAction
    weight
  }

  query GetPatientCheckups($patientId: ID!) {
    checkupsByPatient(patientId: $patientId) {
      ...PatientCheckupsTableItem
    }
  }
`;

interface VitalsTabProps {
  patient: PatientDetailsFragment | undefined;
  patientId: string;
}

export default function VitalsTab({ patientId, patient }: VitalsTabProps) {
  const classes = useStyles();

  const { t } = useTranslation();

  const [numLanguageChangesD3Hack, setNumLanguageChangesD3Hack] = useState(1);

  const [previousAllCheckups, setPreviousAllCheckups] = useState<
    PatientCheckupsTableItemFragment[]
  >([]);
  const [allCheckups, setAllCheckups] = useState<PatientCheckupsTableItemFragment[]>([]);
  const [hasFetchedCheckups, setHasFetchedCheckups] = useState<boolean>(false);
  const [limit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [previousEWSThresholds, setPreviousEWSThresholds] = useState<
    Required<PatientDetailsFragment>['ewsThresholds'] | null
  >(null);
  const [ewsThresholds, setEWSThresholds] = useState<
    Required<PatientDetailsFragment>['ewsThresholds'] | null
  >(null);
  const [ewsThresholdsCreationData, setEWSThresholdsCreationData] = useState<{
    date: string;
    createdBy: FormattableUserFragment;
  }>();

  const [isEditModeSelected, setIsEditModeSelected] = useState<boolean>(false);
  const [isWarningDialogVisible, setIsWarningDialogVisible] = useState<boolean>(false);
  const [isErrorDialogVisible, setIsErrorDialogVisible] = useState<boolean>(false);
  const [hoverCheckupIndex, setHoverCheckupIndex] = useState<number | null>(null);

  // HACK: this use effect ensures that we cause a re-render of the d3 charts when the language changes
  useEffect(() => {
    setNumLanguageChangesD3Hack((current) => current + 1);
  }, [t]);

  const { loading: isLoadingCheckups } = useGetPatientCheckupsQuery({
    variables: { patientId: patientId },
    // Since we are diddling the data in the table, we need to manually store it in state
    onCompleted: (data) => {
      setAllCheckups(data.checkupsByPatient);
      // HACK: Doesn't matter if there's no data, we just want to know if we've fetched it.
      // This is used to delay the render of the VitalSignCharts until any requests have settled.
      // We do this because the charts do not respond well to data being changed after they have been rendered.
      setHasFetchedCheckups(true);
    },
    onError: () => toast.error("An error occured when fetching the patient's checkups"),
  });

  // Here we try to get the thresholds for the patient's CarePathway if they are admitted to a ward,
  // else we take the Organization.defaultCarePathway thresholds for the acting organization, if
  // that is set, else we take the global hard-coded defaults of NEWS2.
  const defaultThresholds =
    patient?.wardAdmission?.carePathway?.ewsThresholds?.thresholds ??
    auth.me('actingOrganization.defaultCarePathway.ewsThresholds.thresholds', undefined) ??
    DEFAULT_EWS_THRESHOLDS;
  const newsName =
    patient?.wardAdmission?.carePathway?.name ??
    auth.me('actingOrganization.defaultCarePathway.name', undefined) ??
    t('NEWS');

  useLayoutEffect(() => {
    if (patient) {
      // Annoying duplication of defaultThresholds to prevent useLayoutEffect from depending on
      // that variable and re-rendering every change. This could probably also be accomplished with
      // useCallback or useMemo.
      const dupeDefaultThresholds =
        patient?.wardAdmission?.carePathway?.ewsThresholds?.thresholds ??
        auth.me('actingOrganization.defaultCarePathway.ewsThresholds.thresholds', undefined) ??
        DEFAULT_EWS_THRESHOLDS;

      const ewsThresholds = patient.ewsThresholds?.thresholds || dupeDefaultThresholds;

      setEWSThresholds(ewsThresholds);
      if (patient.ewsThresholds) {
        setEWSThresholdsCreationData({
          date: patient.ewsThresholds.createdAt,
          createdBy: patient.ewsThresholds.createdBy,
        });
      }
    }
  }, [patient]);

  const showLoading = !patient || !ewsThresholds || isLoadingCheckups || !hasFetchedCheckups;

  const currentPageOfCheckups = useMemo(() => {
    const newCheckups = allCheckups.slice(offset, offset + limit).reverse();
    if (newCheckups.length < limit) {
      // Fill the reminder of the array with `undefined` to ensure the table always has
      // a fixed size
      const numFiller = limit - newCheckups.length;
      newCheckups.splice(newCheckups.length, 0, ...new Array(numFiller));
    }
    return newCheckups;
  }, [allCheckups, limit, offset]);

  const _lastOffset = () => {
    return Math.floor(allCheckups.length / limit) * limit;
  };

  const firstPage = () => setOffset(_lastOffset());
  const previousPage = () => setOffset(Math.min(_lastOffset(), offset + limit));
  const nextPage = () => setOffset(Math.max(0, offset - limit));
  const lastPage = () => setOffset(0);

  const isPagingBackwardDisabled = () => offset >= _lastOffset();
  const isPagingForwardDisabled = () => offset <= 0;

  const calculateEWSScoresInt = (thresholds: any, allCheckups: any[]) => {
    const newAllCheckups = _.cloneDeep(allCheckups);

    for (const checkup of newAllCheckups) {
      const scores = calculateEWSScores(thresholds, checkup);

      _.set(checkup, 'ewsScores.RRScore', _.get(scores, 'RRScore', null));
      _.set(checkup, 'ewsScores.SpO2Score', _.get(scores, 'SpO2Score', null));
      _.set(checkup, 'ewsScores.BPScore', _.get(scores, 'BPScore', null));
      _.set(checkup, 'ewsScores.HRScore', _.get(scores, 'HRScore', null));
      _.set(checkup, 'ewsScores.tempScore', _.get(scores, 'tempScore', null));
    }

    setAllCheckups(newAllCheckups);
  };

  const enterEditMode = () => {
    setIsEditModeSelected(true);
    setPreviousEWSThresholds(ewsThresholds);
    setPreviousAllCheckups(allCheckups);
  };

  const cancelEditMode = () => {
    setIsEditModeSelected(false);
    setEWSThresholds(previousEWSThresholds);
    setAllCheckups(previousAllCheckups ?? []);
  };

  const updateEWSThresholds = (
    newThresholds: any,
    previousThresholds: any,
    thresholdSelector: any,
  ) => {
    const bandScores = previousThresholds.map((band: any) => band.score).reverse();

    const newBands = [];
    newBands.push({
      low: newThresholds[newThresholds.length - 1],
      score: bandScores[bandScores.length - 1],
    });
    for (let i = newThresholds.length - 1; i > 0; i--) {
      newBands.push({ high: newThresholds[i], low: newThresholds[i - 1], score: bandScores[i] });
    }
    newBands.push({ high: newThresholds[0], score: bandScores[0] });

    const newEWSThresholds = _.cloneDeep(ewsThresholds);
    _.set(newEWSThresholds as any, thresholdSelector, newBands);

    setEWSThresholds(newEWSThresholds);
    calculateEWSScoresInt(newEWSThresholds, allCheckups);
  };

  const handleOpenWarningDialog = () => {
    setIsWarningDialogVisible(true);
  };

  const handleCloseWarningDialog = () => {
    setIsWarningDialogVisible(false);
  };

  const handleOpenErrorDialog = () => {
    setIsErrorDialogVisible(true);
  };

  const handleCloseErrorDialog = () => {
    setIsErrorDialogVisible(false);
  };

  const saveThresholdsSelection = async () => {
    // If the thresholds are the same as the default thresholds (i.e. from the care pathway),
    // then we "blank" the thresholds by sending null to the server. This means the patient will
    // now use and track the thresholds from the care pathway.
    const areThresholdsEqual = _.isEqual(ewsThresholds, defaultThresholds);
    const response = await api.createEWSThresholds({
      patientId: patientId,
      thresholds: areThresholdsEqual ? null : ewsThresholds,
    });
    if (response.errors) {
      handleOpenErrorDialog();
    } else {
      const jobRole = {
        jobRole: auth.me('jobRole'),
        organization: { id: auth.me('actingOrganization.id') },
      };

      setEWSThresholdsCreationData({
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        createdBy: {
          email: auth.me('email'),
          firstName: auth.me('firstName'),
          lastName: auth.me('lastName'),
          isSelfCare: false,
          isActiveTeamMember: true,
          jobRoles: jobRole ? [jobRole] : [],
        },
      });
      setIsEditModeSelected(false);
      setAllCheckups(previousAllCheckups);
    }
  };

  const confirmWarningDialog = () => {
    enterEditMode();
    handleCloseWarningDialog();
  };

  const renderWarningDialog = () => {
    return (
      <Dialog
        open={isWarningDialogVisible}
        onClose={handleCloseWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Editing Patient Personal EWS'}</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Editing the patient Personal Early Warning Score (EWS) could affect the resulting risk
            score and recommended action.
          </Typography>
          <Typography>
            Review the effects on previous check-ups and compare to the National EWS and current EWS
            to evaluate changes before saving.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarningDialog}>Cancel</Button>
          <Button onClick={confirmWarningDialog} color="primary" variant="contained">
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderErrorDialog = () => {
    return (
      <Dialog
        open={isErrorDialogVisible}
        onClose={handleCloseErrorDialog}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description">
        <DialogTitle id="error-dialog-title">{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="error-dialog-description">
            There was an error when saving the personalized thresholds, please retry.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderEWSThresholdsDetails = () => {
    const { date, createdBy } = ewsThresholdsCreationData ?? {};
    if (date && createdBy) {
      return (
        <div className={classes.ewsThresholdsDetails}>
          {/* FIXME: We need to set userActingOrganization here but it's not available on the
                     PatientEWSThresholds and CarePathwayEWSThresholds tables yet */}
          <UserName user={createdBy} /> set thresholds on{' '}
          {LocaleFormatting.formatCheckupTimeLongWithoutWeekDay(date)}
        </div>
      );
    }
    return null;
  };

  return (
    <Loading showLoading={showLoading}>
      <>
        {currentPageOfCheckups && patient && (
          <div>
            <div
              className={classes.actionHeader}
              style={{ backgroundColor: isEditModeSelected ? '#d0ecf4' : '' }}>
              <div className={classes.viewSelector}>
                {isEditModeSelected && <div>Editing Patients Personal Early Warning Score</div>}
              </div>
              {isEditModeSelected && renderEWSThresholdsDetails()}
              <div className={classes.buttonWrap}>
                <EditSaveThresholdsSelector
                  isEditModeSelected={isEditModeSelected}
                  handleOpenWarningDialog={handleOpenWarningDialog}
                  cancelEditMode={cancelEditMode}
                  saveThresholdsSelection={saveThresholdsSelection}
                />
              </div>
            </div>
          </div>
        )}
        {!isLoadingCheckups && hasFetchedCheckups && (
          <div className={classes.patientDetail}>
            {renderWarningDialog()}
            {renderErrorDialog()}
            {currentPageOfCheckups && patient && (
              <div className={classes.tableAndChartsContainer}>
                <div className={classes.tableContainer}>
                  <CheckupSummaryTable
                    colorBands={COLOR_BANDS}
                    checkupData={currentPageOfCheckups}
                    id={patient.id}
                    hoverCheckupIndex={hoverCheckupIndex}
                    firstPage={() => firstPage()}
                    previousPage={() => previousPage()}
                    nextPage={() => nextPage()}
                    lastPage={() => lastPage()}
                    isFirstPageDisabled={isPagingBackwardDisabled()}
                    isPreviousPageDisabled={isPagingBackwardDisabled()}
                    isNextPageDisabled={isPagingForwardDisabled()}
                    isLastPageDisabled={isPagingForwardDisabled()}
                    limit={limit}
                    offset={offset}
                    total={allCheckups.length}
                  />
                  {auth.me('actingOrganization.features.continuousMonitoring', false) && (
                    <ContinuousMonitoringSummaryTable patientId={patient.id} />
                  )}
                  {auth.me('actingOrganization.features.activityMonitoring', false) && (
                    <div>
                      <ActivityMonitoringSummaryTable patientId={patient.id} />
                      <Explainer
                        question="Why is gait speed not displayed from day 1?"
                        sx={{ marginX: 3 }}>
                        <Typography variant="body2">
                          This is a maximum value over a 7-day period which is first calculated and
                          displayed on day 8 after an activity monitoring session has been started,
                          and daily thereafter. Gait speed is not a clinically reliable vital sign
                          before day 8.
                        </Typography>
                      </Explainer>
                    </div>
                  )}
                </div>

                <div
                  // HACK: This key exists to give VitalSignChart a kick to re-render since it lacks sufficient change detection
                  key={isEditModeSelected.toString()}
                  className={classes.vitalChartsContainer}>
                  <div
                    // HACK: This key exists to give VitalSignChart a kick to re-render since it lacks sufficient change detection
                    key={offset + numLanguageChangesD3Hack}>
                    <VitalSignChart
                      title="Breathing Rate"
                      subtitle="Respirations (breaths/min)"
                      checkupData={currentPageOfCheckups}
                      colorBands={COLOR_BANDS}
                      grayColorBands={GRAY_COLOR_BANDS}
                      hoverCheckupIndex={hoverCheckupIndex}
                      setHoverCheckupIndex={setHoverCheckupIndex}
                      updateEWSThresholds={updateEWSThresholds}
                      vitalSelector="respiratoryRate.value"
                      ewsScoreSelector="ewsScores.RRScore"
                      thresholdSelector="respiratoryRate"
                      ewsThresholds={_.get(ewsThresholds, 'respiratoryRate')}
                      newsThresholds={_.get(defaultThresholds, 'respiratoryRate')}
                      newsName={newsName}
                      isEditModeSelected={isEditModeSelected}
                      defaultLegendDomain={[4, 40]}
                      legendAxisStepSize={1}
                      yAxisStepSize={2}
                      unitFormat=".0f"
                    />
                    <VitalSignChart
                      title="Oxygen Saturation"
                      subtitle="SpO2 (%)"
                      checkupData={currentPageOfCheckups}
                      colorBands={COLOR_BANDS}
                      grayColorBands={GRAY_COLOR_BANDS}
                      hoverCheckupIndex={hoverCheckupIndex}
                      setHoverCheckupIndex={setHoverCheckupIndex}
                      updateEWSThresholds={updateEWSThresholds}
                      vitalSelector="pulseOxiData.averageSpO2"
                      ewsScoreSelector="ewsScores.SpO2Score"
                      thresholdSelector="spO2"
                      ewsThresholds={_.get(ewsThresholds, 'spO2')}
                      newsThresholds={_.get(defaultThresholds, 'spO2')}
                      newsName={newsName}
                      isEditModeSelected={isEditModeSelected}
                      defaultLegendDomain={[60, 100]}
                      legendAxisStepSize={1}
                      yAxisStepSize={2}
                      unitFormat=".0f"
                    />
                    <VitalSignChart
                      title="Blood Pressure"
                      subtitle="Systolic BP (mmHg)"
                      colorBands={COLOR_BANDS}
                      grayColorBands={GRAY_COLOR_BANDS}
                      checkupData={currentPageOfCheckups}
                      hoverCheckupIndex={hoverCheckupIndex}
                      setHoverCheckupIndex={setHoverCheckupIndex}
                      updateEWSThresholds={updateEWSThresholds}
                      vitalSelector="bloodPressureData.systolic"
                      ewsScoreSelector="ewsScores.BPScore"
                      thresholdSelector="bloodPressure"
                      ewsThresholds={_.get(ewsThresholds, 'bloodPressure')}
                      newsThresholds={_.get(defaultThresholds, 'bloodPressure')}
                      newsName={newsName}
                      isEditModeSelected={isEditModeSelected}
                      defaultLegendDomain={[50, 250]}
                      legendAxisStepSize={1}
                      yAxisStepSize={10}
                      unitFormat=".0f"
                    />
                    <VitalSignChart
                      title="Pulse Rate"
                      subtitle="Unit (beats/min)"
                      checkupData={currentPageOfCheckups}
                      colorBands={COLOR_BANDS}
                      grayColorBands={GRAY_COLOR_BANDS}
                      hoverCheckupIndex={hoverCheckupIndex}
                      setHoverCheckupIndex={setHoverCheckupIndex}
                      updateEWSThresholds={updateEWSThresholds}
                      vitalSelector="pulseRate.value"
                      ewsScoreSelector="ewsScores.HRScore"
                      thresholdSelector="pulse"
                      ewsThresholds={_.get(ewsThresholds, 'pulse')}
                      newsThresholds={_.get(defaultThresholds, 'pulse')}
                      newsName={newsName}
                      isEditModeSelected={isEditModeSelected}
                      defaultLegendDomain={[30, 170]}
                      legendAxisStepSize={1}
                      yAxisStepSize={10}
                      unitFormat=".0f"
                    />
                    <VitalSignChart
                      title="Temperature"
                      subtitle={`Unit (${feebrisFormatter.temperatureUnits()})`}
                      checkupData={currentPageOfCheckups}
                      colorBands={COLOR_BANDS}
                      grayColorBands={GRAY_COLOR_BANDS}
                      hoverCheckupIndex={hoverCheckupIndex}
                      setHoverCheckupIndex={setHoverCheckupIndex}
                      updateEWSThresholds={updateEWSThresholds}
                      vitalSelector="temperature"
                      ewsScoreSelector="ewsScores.tempScore"
                      thresholdSelector="temperature"
                      ewsThresholds={_.get(ewsThresholds, 'temperature')}
                      newsThresholds={_.get(defaultThresholds, 'temperature')}
                      newsName={newsName}
                      isEditModeSelected={isEditModeSelected}
                      defaultLegendDomain={[33, 43]}
                      legendAxisStepSize={0.1}
                      yAxisStepSize={0.5}
                      unitFormat=".1f"
                      extraFormatter={(v: any) => parseFloat(feebrisFormatter.formatTemperature(v))}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    </Loading>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  patientDetail: {
    maxWidth: '1400px',
    margin: '0 auto',
    width: '100%',
  },
  actionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap-reverse',
    marginBottom: '30px',
    padding: theme.spacing(2, 3),
  },
  viewSelector: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(0.5, 0),
  },
  icon: {
    marginRight: '5px',
  },
  viewText: {
    color: '#009ac9',
    marginRight: '15px',
  },
  tableAndChartsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 4,
    position: 'sticky',
    top: '80px',
    height: '100%',
  },
  vitalChartsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 3,
    borderLeft: '1px solid rgba(0,0,0,0.1)',
  },
  buttonWrap: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0.5, 0),
    // add left margin to all buttons except the first one
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
  ewsThresholdsDetails: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
}));
