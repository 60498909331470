import React from 'react';
import { useTranslation } from 'react-i18next';

import '@/styles/components/blocks/block-questionnaire.scss';

import { SoftSignsChips } from '@/components/SoftSignsChips';
import { SoftSign } from '@/generated/graphql';

interface BlockQuestionnaireProps {
  softSigns: Pick<SoftSign, 'key' | 'isEmergency'>[];
}

export default function BlockQuestionnaire({ softSigns }: BlockQuestionnaireProps) {
  const { t } = useTranslation();

  const hasSoftSigns = softSigns.length > 0;

  return (
    <div className="block block--questionnaire">
      <div className="block__title">{t('Soft Signs')}</div>
      <div className="block__inner">
        {!hasSoftSigns ? (
          <div className="block__cols">
            <div>
              <p className="value">-</p>
            </div>
          </div>
        ) : (
          <SoftSignsChips showHeader={false} softSigns={softSigns} />
        )}
      </div>
    </div>
  );
}
