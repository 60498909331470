import { ConsciousnessType } from '../controllers/types';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

/* eslint-disable */
/* This file is automatically generated by graphql-codegen DO NOT EDIT!!! */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {"context":{"clientName":"internal"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  JSON: any;
  JSONObject: Record<string, any>;
};

export type AcceptedTermsInternal = {
  __typename?: 'AcceptedTerms';
  acceptedBy: UserInternal;
  id: Scalars['ID'];
  organization: OrganizationInternal;
  version: Scalars['Int'];
};

export type ActionLogInternal = {
  __typename?: 'ActionLog';
  affectedOrganization?: Maybe<OrganizationInternal>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  loggedInAs?: Maybe<UserInternal>;
  message: Scalars['String'];
  messageEntities?: Maybe<Array<MessageEntityInternal>>;
  user?: Maybe<UserInternal>;
};

export type ActionLogsInternal = {
  __typename?: 'ActionLogs';
  logs: Array<ActionLogInternal>;
  nextActionLogId?: Maybe<Scalars['ID']>;
};

export type AddressInternal = {
  __typename?: 'Address';
  address: Scalars['String'];
  postcode: Scalars['String'];
};

export type AdmissionEventInternal = {
  __typename?: 'AdmissionEvent';
  PatientId: Scalars['ID'];
  WardId: Scalars['ID'];
  admissionId: Scalars['ID'];
  carePathway?: Maybe<CarePathwayInternal>;
  createdAt: Scalars['Date'];
  createdBy?: Maybe<UserInternal>;
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  type: AdmissionEventTypeInternal;
  ward: WardInternal;
};

export enum AdmissionEventTypeInternal {
  AdmissionInternal = 'Admission',
  CarePathwayTransferInternal = 'CarePathwayTransfer',
  DischargeInternal = 'Discharge',
  WardTransferInternal = 'WardTransfer'
}

export type AggregateMetadataInternal = {
  __typename?: 'AggregateMetadata';
  count?: Maybe<Scalars['Int']>;
};

export type AlertInternal = {
  __typename?: 'Alert';
  _aggregate?: Maybe<AggregateMetadataInternal>;
  alertRule?: Maybe<AlertRuleInternal>;
  checkup?: Maybe<CheckupInternal>;
  checkupScheduleEvent?: Maybe<CheckupScheduleEventInternal>;
  continuousMonitoring?: Maybe<ContinuousMonitoringInternal>;
  endedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pacsanaEvent?: Maybe<PacsanaEventInternal>;
  startedAt?: Maybe<Scalars['Date']>;
  status: AlertStatusInternal;
  type?: Maybe<AlertTypeInternal>;
};

export type AlertCheckupRulePreviewInternal = {
  __typename?: 'AlertCheckupRulePreview';
  condition: Scalars['String'];
  examples: Array<CheckupInternal>;
  numTested: Scalars['Int'];
  percentTriggered: Scalars['Float'];
};

export type AlertContinuousMonitoringRulePreviewInternal = {
  __typename?: 'AlertContinuousMonitoringRulePreview';
  condition: Scalars['String'];
  examples: Array<ContinuousMonitoringInternal>;
  numTested: Scalars['Int'];
  percentTriggered: Scalars['Float'];
};

export type AlertRuleInternal = {
  __typename?: 'AlertRule';
  condition: Scalars['String'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notificationConfig?: Maybe<Scalars['JSONObject']>;
  type: AlertRuleTypesInternal;
  updatedAt: Scalars['Date'];
};

export enum AlertRuleTypesInternal {
  ContinuousMonitoringInternal = 'continuous_monitoring',
  NewCheckupInternal = 'new_checkup'
}

export enum AlertStatusInternal {
  ClosedInternal = 'closed',
  OpenInternal = 'open'
}

export enum AlertTypeInternal {
  AlertRuleInternal = 'AlertRule',
  CheckupScheduleEventInternal = 'CheckupScheduleEvent',
  PacsanaEventInternal = 'PacsanaEvent',
  PatientCallbackRequestInternal = 'PatientCallbackRequest'
}

export type BatteryStatsInternal = {
  __typename?: 'BatteryStats';
  ecg?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

export enum BatteryStatusInternal {
  EndOfLifeInternal = 'EndOfLife',
  ExpiringInternal = 'Expiring',
  OfflineInternal = 'Offline',
  OkayInternal = 'Okay'
}

export type BloodPressureCuffTileConfigInternal = CheckupTileConfigInternal & {
  __typename?: 'BloodPressureCuffTileConfig';
  manualEntry?: Maybe<Scalars['Boolean']>;
  rule: CheckupTileRuleInternal;
};

export type BucketStatsInternal = {
  __typename?: 'BucketStats';
  max?: Maybe<Scalars['Float']>;
  median?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

export type CarePathwayInternal = {
  __typename?: 'CarePathway';
  alertRules: Array<AlertRuleInternal>;
  checkupTypes: Array<CheckupTypeInternal>;
  createdAt: Scalars['Date'];
  createdBy: UserInternal;
  deletedAt?: Maybe<Scalars['Date']>;
  ehrIntegrations: Array<EhrIntegrationInternal>;
  ewsThresholds?: Maybe<EwsThresholdsInternal>;
  id: Scalars['ID'];
  integrations: Array<EhrIntegrationInternal>;
  name: Scalars['String'];
  numberOfPatients: Scalars['Int'];
  organization?: Maybe<OrganizationInternal>;
  publishedAt?: Maybe<Scalars['Date']>;
  updatedAt: Scalars['Date'];
};


export type CarePathwayCheckupTypesArgsInternal = {
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  includeShared?: InputMaybe<Scalars['Boolean']>;
};


export type CarePathwayNumberOfPatientsArgsInternal = {
  includeDischarged?: InputMaybe<Scalars['Boolean']>;
};

export type CarePathwayTemplateInternal = {
  __typename?: 'CarePathwayTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CheckupInternal = {
  __typename?: 'Checkup';
  bloodPressureData?: Maybe<Scalars['JSONObject']>;
  checkupType?: Maybe<CheckupTypeInternal>;
  comment?: Maybe<Scalars['String']>;
  consciousness?: Maybe<ConsciousnessType>;
  createdAt: Scalars['Date'];
  createdBy?: Maybe<UserInternal>;
  createdById: Scalars['ID'];
  createdUsingAppVersion: Scalars['String'];
  csvRecLocation?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Date']>;
  endedAt: Scalars['Date'];
  ewsScores?: Maybe<EwsScoresInternal>;
  ewsThresholds?: Maybe<EwsThresholdsInternal>;
  glucose?: Maybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  isStable?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use 'lungSounds' instead */
  lungRecLocation?: Maybe<Scalars['String']>;
  lungSounds?: Maybe<Array<LungSoundRecordingInternal>>;
  /** @deprecated Use 'notes' instead. This will continue to return the first note only. */
  notes: Array<PatientNoteInternal>;
  organization?: Maybe<OrganizationInternal>;
  patient?: Maybe<PatientInternal>;
  picture?: Maybe<CheckupPictureInternal>;
  pulseOxiData?: Maybe<Scalars['JSONObject']>;
  pulseRate?: Maybe<PulseRateInternal>;
  questionnaire?: Maybe<Array<Scalars['JSONObject']>>;
  respiratoryRate?: Maybe<RespiratoryRateInternal>;
  selectedAction?: Maybe<Scalars['Int']>;
  softSigns?: Maybe<Array<SoftSignInternal>>;
  startedAt: Scalars['Date'];
  subtype: Scalars['String'];
  temperature?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
  weight?: Maybe<Scalars['Float']>;
};

export type CheckupConfigInternal = {
  __typename?: 'CheckupConfig';
  checkupTiles: CheckupTilesInternal;
  description: Scalars['String'];
  heading: Scalars['String'];
  name: Scalars['String'];
  subheading: Scalars['String'];
};

export type CheckupConfigInputInternal = {
  checkupTiles: Scalars['JSONObject'];
  description: Scalars['String'];
  heading: Scalars['String'];
  name: Scalars['String'];
  subheading: Scalars['String'];
};

export type CheckupPictureInternal = {
  __typename?: 'CheckupPicture';
  caption?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type CheckupScheduleInternal = {
  __typename?: 'CheckupSchedule';
  id: Scalars['ID'];
  rrule: Scalars['String'];
  tolerance: CheckupScheduleToleranceInternal;
};

export type CheckupScheduleEventInternal = {
  __typename?: 'CheckupScheduleEvent';
  checkupExpectedAt: Scalars['Date'];
  checkupSchedule: CheckupScheduleInternal;
  id: Scalars['ID'];
};

export type CheckupScheduleInputInternal = {
  ruleOptions: Scalars['JSONObject'];
  tolerance: CheckupScheduleToleranceInputInternal;
};

export type CheckupScheduleToleranceInternal = {
  __typename?: 'CheckupScheduleTolerance';
  early: Scalars['Int'];
  late: Scalars['Int'];
};

export type CheckupScheduleToleranceInputInternal = {
  /** Early checkup tolerance in seconds */
  early: Scalars['Int'];
  /** Late checkup tolerance in seconds */
  late: Scalars['Int'];
};

export type CheckupTileConfigInternal = {
  rule: CheckupTileRuleInternal;
};

export enum CheckupTileRuleInternal {
  DisabledInternal = 'disabled',
  OptionalInternal = 'optional',
  RequiredInternal = 'required'
}

export type CheckupTilesInternal = {
  __typename?: 'CheckupTiles';
  bloodPressureCuff?: Maybe<BloodPressureCuffTileConfigInternal>;
  consciousness?: Maybe<GenericCheckupTileConfigInternal>;
  glucose?: Maybe<GenericCheckupTileConfigInternal>;
  picture?: Maybe<GenericCheckupTileConfigInternal>;
  pulseOximeter?: Maybe<PulseOximeterTileConfigInternal>;
  respiratoryRate?: Maybe<GenericCheckupTileConfigInternal>;
  stethoscope?: Maybe<StethoscopeTileConfigInternal>;
  symptomsQuestionnaire?: Maybe<SymptomsQuestionnaireTileConfigInternal>;
  temperature?: Maybe<GenericCheckupTileConfigInternal>;
  weight?: Maybe<GenericCheckupTileConfigInternal>;
};

export type CheckupTilesInputInternal = {
  checkupTiles?: InputMaybe<Array<Scalars['String']>>;
};

export type CheckupTypeInternal = {
  __typename?: 'CheckupType';
  checkupTiles: CheckupTilesInternal;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  description: Scalars['String'];
  heading: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  numberOfCheckups: Scalars['Int'];
  schedule?: Maybe<CheckupScheduleInternal>;
  subheading: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type CheckupTypeInputInternal = {
  checkupTiles: Scalars['JSONObject'];
  description: Scalars['String'];
  heading: Scalars['String'];
  name: Scalars['String'];
  subheading: Scalars['String'];
};

export type CheckupTypeUpdateInputInternal = {
  checkupTiles: Scalars['JSONObject'];
  description: Scalars['String'];
  heading: Scalars['String'];
  name: Scalars['String'];
  subheading: Scalars['String'];
};

export type CodedDataItemInternal = {
  __typename?: 'CodedDataItem';
  additionalText: Scalars['String'];
  code: Scalars['String'];
  units: Scalars['String'];
  value: Scalars['Float'];
};

export enum CodedVitalInternal {
  DiastolicBloodPressureInternal = 'diastolicBloodPressure',
  DiastolicBloodPressureLyingInternal = 'diastolicBloodPressure_lying',
  DiastolicBloodPressureSittingInternal = 'diastolicBloodPressure_sitting',
  DiastolicBloodPressureStandingInternal = 'diastolicBloodPressure_standing',
  GlucoseInternal = 'glucose',
  PulseRateInternal = 'pulseRate',
  RespiratoryRateInternal = 'respiratoryRate',
  SpO2Internal = 'spO2',
  SystolicBloodPressureInternal = 'systolicBloodPressure',
  SystolicBloodPressureLyingInternal = 'systolicBloodPressure_lying',
  SystolicBloodPressureSittingInternal = 'systolicBloodPressure_sitting',
  SystolicBloodPressureStandingInternal = 'systolicBloodPressure_standing',
  TemperatureInternal = 'temperature',
  WeightInternal = 'weight'
}

export { ConsciousnessType };

export type ContactDetailsInputInternal = {
  address?: InputMaybe<InputAddressInternal>;
  telephone?: InputMaybe<Scalars['String']>;
};

export type ContinuousMonitoringInternal = {
  __typename?: 'ContinuousMonitoring';
  battery?: Maybe<BatteryStatsInternal>;
  bucketEndAt: Scalars['Date'];
  bucketSize: Scalars['Int'];
  bucketStartAt: Scalars['Date'];
  heartRate?: Maybe<BucketStatsInternal>;
  id: Scalars['ID'];
  patient?: Maybe<PatientInternal>;
  pulseRate?: Maybe<BucketStatsInternal>;
  respiratoryRate?: Maybe<BucketStatsInternal>;
  spo2?: Maybe<BucketStatsInternal>;
  temperature?: Maybe<BucketStatsInternal>;
  thresholdScores?: Maybe<EwsScoresInternal>;
};

export type ContinuousMonitoringPagesInternal = {
  __typename?: 'ContinuousMonitoringPages';
  data: Array<ContinuousMonitoringInternal>;
  earliest?: Maybe<Scalars['Date']>;
  from?: Maybe<Scalars['Date']>;
  latest?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type ContinuousMonitoringSessionInternal = {
  __typename?: 'ContinuousMonitoringSession';
  createdAt: Scalars['Date'];
  earliestBySensor?: Maybe<VivalinkSensorDataInternal>;
  endedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  latestBySensor?: Maybe<VivalinkSensorDataInternal>;
  organization: OrganizationInternal;
  patient: PatientInternal;
  vivalinkSubjectId: Scalars['String'];
};

export type ContinuousMonitoringWithSessionInternal = {
  __typename?: 'ContinuousMonitoringWithSession';
  continuousMonitoring?: Maybe<ContinuousMonitoringInternal>;
  continuousMonitoringSession: ContinuousMonitoringSessionInternal;
};

export type CreateEhrIntegrationInputInternal = {
  dataPointTriggerConfig?: InputMaybe<EhrIntegrationDataPointTriggerConfigInputInternal>;
  integrationType: EhrIntegrationTypeInternal;
  periodicTriggerConfig?: InputMaybe<EhrIntegrationPeriodicTriggerConfigInputInternal>;
  triggerType: EhrIntegrationTriggerTypeInternal;
};

export type CreatePatientInputInternal = {
  address?: InputMaybe<InputAddressInternal>;
  birthDate: Scalars['Date'];
  firstName: Scalars['String'];
  gender: Scalars['String'];
  lastName: Scalars['String'];
  nhsNumber?: InputMaybe<Scalars['String']>;
  preExistingConditions?: InputMaybe<Scalars['String']>;
  selfCare?: InputMaybe<SelfCareInputInternal>;
  telephone?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type EwsScoresInternal = {
  __typename?: 'EWSScores';
  BPScore?: Maybe<Scalars['Int']>;
  HRScore?: Maybe<Scalars['Int']>;
  RRScore?: Maybe<Scalars['Int']>;
  SpO2Score?: Maybe<Scalars['Int']>;
  consciousnessScore?: Maybe<Scalars['Int']>;
  riskLevel?: Maybe<Scalars['Int']>;
  tempScore?: Maybe<Scalars['Int']>;
  totalScore?: Maybe<Scalars['Int']>;
};

export type EwsThresholdsInternal = {
  __typename?: 'EWSThresholds';
  createdAt: Scalars['Date'];
  createdBy: UserInternal;
  id: Scalars['ID'];
  patient?: Maybe<PatientInternal>;
  thresholds?: Maybe<Scalars['JSONObject']>;
  updatedAt: Scalars['Date'];
};

export type EhrClinicalTermMappingInternal = {
  __typename?: 'EhrClinicalTermMapping';
  softSigns: Array<SoftSignCodingInternal>;
  vitals: Array<VitalCodingInternal>;
};

export type EhrClinicalTermMappingInputInternal = {
  softSigns: Array<EhrSoftSignCodingInputInternal>;
  vitals: Array<EhrVitalCodingInputInternal>;
};

export enum EhrIdentityTypeInternal {
  QuicksilvaInternal = 'Quicksilva'
}

export type EhrIntegrationInternal = {
  __typename?: 'EhrIntegration';
  authorizedAutomaticApprover?: Maybe<UserInternal>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  integrationType: EhrIntegrationTypeInternal;
  triggerConfig?: Maybe<EhrIntegrationTriggerConfigInternal>;
  triggerType: EhrIntegrationTriggerTypeInternal;
  updatedAt: Scalars['Date'];
};

export type EhrIntegrationDataPointTriggerConfigInternal = {
  __typename?: 'EhrIntegrationDataPointTriggerConfig';
  dataDispatchThrottleInMinutes?: Maybe<Scalars['Int']>;
  totalScoreThreshold?: Maybe<Scalars['Int']>;
  vitalSignScoreThresholds?: Maybe<EhrIntegrationDataPointVitalScoreTriggerConfigInternal>;
};

export type EhrIntegrationDataPointTriggerConfigInputInternal = {
  dataDispatchThrottleInMinutes?: InputMaybe<Scalars['Int']>;
  totalScoreThreshold?: InputMaybe<Scalars['Int']>;
  vitalSignScoreThresholds?: InputMaybe<EhrIntegrationDataPointVitalScoreTriggerConfigInputInternal>;
};

export type EhrIntegrationDataPointVitalScoreTriggerConfigInternal = {
  __typename?: 'EhrIntegrationDataPointVitalScoreTriggerConfig';
  bloodPressure?: Maybe<Scalars['Int']>;
  pulseRate?: Maybe<Scalars['Int']>;
  respiratoryRate?: Maybe<Scalars['Int']>;
  spO2?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Int']>;
};

export type EhrIntegrationDataPointVitalScoreTriggerConfigInputInternal = {
  bloodPressure?: InputMaybe<Scalars['Int']>;
  pulseRate?: InputMaybe<Scalars['Int']>;
  respiratoryRate?: InputMaybe<Scalars['Int']>;
  spO2?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Int']>;
};

export type EhrIntegrationEventInternal = {
  __typename?: 'EhrIntegrationEvent';
  /** Only available for data point events */
  checkup?: Maybe<CheckupInternal>;
  continuousMonitoring?: Maybe<ContinuousMonitoringInternal>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  integration: EhrIntegrationInternal;
  isRetryable: Scalars['Boolean'];
  patient: PatientInternal;
  requestBody?: Maybe<EhrIntegrationEventRequestBodyInternal>;
  response?: Maybe<EhrIntegrationResponseInternal>;
  status: EhrIntegrationStatusInternal;
  /** Only available for periodic summary events */
  summaryPeriod?: Maybe<EhrSummaryPeriodInternal>;
  updatedAt: Scalars['Date'];
};

export type EhrIntegrationEventQuerySortInternal = {
  direction: SortDirectionInternal;
  field: EhrIntegrationEventQuerySortFieldInternal;
};

export enum EhrIntegrationEventQuerySortFieldInternal {
  CreatedAtInternal = 'createdAt',
  StatusInternal = 'status',
  UpdatedAtInternal = 'updatedAt'
}

export type EhrIntegrationEventRequestBodyInternal = {
  __typename?: 'EhrIntegrationEventRequestBody';
  codedDataItems: Array<CodedDataItemInternal>;
  consultationNote?: Maybe<Scalars['String']>;
};

export enum EhrIntegrationEventRequestTypeInternal {
  ToolbarInstantMessageInternal = 'ToolbarInstantMessage'
}

export type EhrIntegrationEventsInternal = {
  __typename?: 'EhrIntegrationEvents';
  events: Array<EhrIntegrationEventInternal>;
  totalCount: Scalars['Int'];
};

export type EhrIntegrationIdentityInternal = {
  __typename?: 'EhrIntegrationIdentity';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  identityType: EhrIdentityTypeInternal;
};

export type EhrIntegrationPeriodicTriggerConfigInternal = {
  __typename?: 'EhrIntegrationPeriodicTriggerConfig';
  rrule: Scalars['String'];
};

export type EhrIntegrationPeriodicTriggerConfigInputInternal = {
  rrule: Scalars['String'];
};

export type EhrIntegrationResponseInternal = {
  __typename?: 'EhrIntegrationResponse';
  innerError?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export enum EhrIntegrationStatusInternal {
  ActionRequiredInternal = 'action_required',
  ErrorInternal = 'error',
  PendingInternal = 'pending',
  RejectedInternal = 'rejected',
  SkippedInternal = 'skipped',
  SubmittedInternal = 'submitted',
  SuccessInternal = 'success'
}

export type EhrIntegrationTriggerConfigInternal = EhrIntegrationDataPointTriggerConfigInternal | EhrIntegrationPeriodicTriggerConfigInternal;

export enum EhrIntegrationTriggerTypeInternal {
  DataPointInternal = 'DataPoint',
  PeriodicSummaryInternal = 'PeriodicSummary'
}

export enum EhrIntegrationTypeInternal {
  QuicksilvaMessageInternal = 'QuicksilvaMessage'
}

export type EhrSoftSignCodingInputInternal = {
  code: Scalars['String'];
  softSign: Scalars['String'];
};

export type EhrSummaryPeriodInternal = {
  __typename?: 'EhrSummaryPeriod';
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type EhrVitalCodingInputInternal = {
  code: Scalars['String'];
  unitText?: InputMaybe<Scalars['String']>;
  vital: CodedVitalInternal;
};

export enum GatewayStatusInternal {
  AllGatewaysOfflineInternal = 'AllGatewaysOffline',
  AllGatewaysOnlineInternal = 'AllGatewaysOnline',
  NoGatewaysInternal = 'NoGateways',
  SomeGatewaysOfflineInternal = 'SomeGatewaysOffline'
}

export enum GenderInternal {
  FemaleInternal = 'female',
  MaleInternal = 'male'
}

export type GenericCheckupTileConfigInternal = CheckupTileConfigInternal & {
  __typename?: 'GenericCheckupTileConfig';
  rule: CheckupTileRuleInternal;
};

export type GlobalStateInternal = {
  __typename?: 'GlobalState';
  key: Scalars['String'];
  value: Scalars['JSONObject'];
};

export type InputAddressInternal = {
  address: Scalars['String'];
  postcode: Scalars['String'];
};

export type IntegrationApiKeyInternal = IntegrationApiKeyInterfaceInternal & {
  __typename?: 'IntegrationApiKey';
  apiKey: Scalars['String'];
  createdAt: Scalars['Date'];
  createdBy?: Maybe<UserInternal>;
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
};

export type IntegrationApiKeyInterfaceInternal = {
  apiKey: Scalars['String'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
};

export type IntegrationApiKeyWithSecretInternal = IntegrationApiKeyInterfaceInternal & {
  __typename?: 'IntegrationApiKeyWithSecret';
  apiKey: Scalars['String'];
  createdAt: Scalars['Date'];
  createdBy?: Maybe<UserInternal>;
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  secret: Scalars['String'];
};

export type JobRoleInternal = {
  __typename?: 'JobRole';
  jobRole: Scalars['String'];
  organization: OrganizationInternal;
};

export type LoginAsTokensInternal = {
  __typename?: 'LoginAsTokens';
  exitLoginAsToken: Scalars['String'];
  loginAsToken: Scalars['String'];
};

export type LungSoundRecordingInternal = {
  __typename?: 'LungSoundRecording';
  url: Scalars['String'];
};

export type MeInternal = UserInterfaceInternal & {
  __typename?: 'Me';
  actingOrganization: OrganizationInternal;
  canReceivePushNotifications: Scalars['Boolean'];
  canResetPassword: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  devices: Array<UserDeviceInternal>;
  email: Scalars['String'];
  enrolledInTotpMfa: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intercomUserHash?: Maybe<Scalars['String']>;
  isFeebroid: Scalars['Boolean'];
  isQuicksilvaIdentitySetup: Scalars['Boolean'];
  /** The role the user takes in their organization, distinct from Feebris role */
  jobRole?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organizations: Array<OrganizationInternal>;
  permissions: Scalars['JSONObject'];
  roles: Array<Scalars['String']>;
  wards: Array<WardInternal>;
};

export type MeInputInternal = {
  firstName?: InputMaybe<Scalars['String']>;
  jobRole?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type MessageEntityInternal = {
  __typename?: 'MessageEntity';
  indices?: Maybe<Array<Scalars['Int']>>;
  instance?: Maybe<MessageEntityInstanceInternal>;
  text?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type MessageEntityInstanceInternal = AcceptedTermsInternal | AlertInternal | AlertRuleInternal | CarePathwayInternal | CheckupInternal | CheckupConfigInternal | CheckupScheduleInternal | CheckupScheduleEventInternal | CheckupTypeInternal | ContinuousMonitoringInternal | ContinuousMonitoringSessionInternal | EwsThresholdsInternal | EhrIntegrationInternal | EhrIntegrationEventInternal | EhrIntegrationIdentityInternal | IntegrationApiKeyInternal | NhsNumberInternal | OrganizationInternal | OrganizationPatientInternal | PacsanaEventInternal | PacsanaSessionInternal | PatientInternal | PatientNoteInternal | PictureInternal | ShareTokenInternal | UserInternal | UserOrganizationInternal | WardInternal | WardStaffInternal;

export type MetricAlgorithmExecutionInputInternal = {
  algorithmId: Scalars['String'];
  algorithmVersion: Scalars['String'];
  context?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  executionTime: Scalars['Int'];
  id: Scalars['ID'];
  value: Scalars['Float'];
};

export type MetricAppActionInputInternal = {
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  screen: Scalars['String'];
  userEvent: Scalars['String'];
};

export type MobileDeviceMetadataInputInternal = {
  brand: Scalars['String'];
  deviceId: Scalars['String'];
  manufacturer: Scalars['String'];
  model: Scalars['String'];
  name: Scalars['String'];
  os: Scalars['String'];
  osVersion: Scalars['String'];
};

export type MutationInternal = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  acceptTerms?: Maybe<Scalars['Boolean']>;
  addCheckupType: CheckupTypeInternal;
  addPatientNote: PatientNoteInternal;
  addQuicksilvaIdentity: Scalars['Boolean'];
  addToWatchList: PatientInternal;
  admitPatientToWard: WardPatientInternal;
  assignPatientToPractice: OrganizationPatientInternal;
  assignStaffToWard: WardStaffInternal;
  captureMetricAlgorithmExecution?: Maybe<Scalars['Boolean']>;
  captureMetricAppAction?: Maybe<Scalars['Boolean']>;
  createAlertRule?: Maybe<AlertRuleInternal>;
  createCarePathway: CarePathwayInternal;
  createCheckupConfig?: Maybe<Scalars['Boolean']>;
  createEWSThresholds: EwsThresholdsInternal;
  createEhrIntegration: EhrIntegrationInternal;
  createFirebaseUser: UserInternal;
  createIntegrationApiKey: IntegrationApiKeyWithSecretInternal;
  createOrganization?: Maybe<OrganizationInternal>;
  createOrganizationAndFirstUser?: Maybe<OrganizationAndUserInternal>;
  createPatient: PatientInternal;
  createPatientCallbackRequest?: Maybe<AlertInternal>;
  createPractice: OrganizationInternal;
  createSelfCareLogin: UserInternal;
  createUser: UserInternal;
  createVideoCallRoom: VideoCallDetailsInternal;
  createWard: WardInternal;
  deleteAlertRule?: Maybe<Scalars['Boolean']>;
  deleteCheckupSchedule: Scalars['Boolean'];
  deleteCheckupType: Scalars['Boolean'];
  deleteEhrIntegration: Scalars['Boolean'];
  deletePatient: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  deleteWard?: Maybe<WardInternal>;
  didLogin: Scalars['Boolean'];
  didStayLoggedIn: Scalars['Boolean'];
  didSwitchActingOrganization: Scalars['Boolean'];
  dischargePatientFromWard: Scalars['Boolean'];
  endContinuousMonitoringSession: ContinuousMonitoringSessionInternal;
  endPacsanaSession: PacsanaSessionInternal;
  expireIntegrationApiKey: Scalars['Boolean'];
  loginAs?: Maybe<LoginAsTokensInternal>;
  markMyEmailAsVerified: Scalars['Boolean'];
  passwordResetUrl: Scalars['String'];
  previewCheckupAlertRule?: Maybe<AlertCheckupRulePreviewInternal>;
  previewContinuousMonitoringAlertRule?: Maybe<AlertContinuousMonitoringRulePreviewInternal>;
  registerAsEhrIntegrationAutoApprover: EhrIntegrationInternal;
  registerDeviceToken: UserDeviceInternal;
  removeEhrIntegrationAutoApprover: EhrIntegrationInternal;
  removeFromWatchList: PatientInternal;
  removeQuicksilvaIdentity: Scalars['Boolean'];
  removeStaffFromWard: Scalars['Boolean'];
  retryEhrIntegrationEvent: EhrIntegrationEventInternal;
  sendPushNotification: Scalars['Boolean'];
  sendWelcomeEmail?: Maybe<Scalars['Boolean']>;
  setAlertStatus?: Maybe<Scalars['Boolean']>;
  setCarePathwayThresholds: CarePathwayInternal;
  setCheckupTypeSchedule: CheckupScheduleInternal;
  setEhrClinicalTermMapping: EhrClinicalTermMappingInternal;
  setPasswordForSelfCareUser: Scalars['Boolean'];
  startContinuousMonitoringSession: ContinuousMonitoringSessionInternal;
  startPacsanaSession: PacsanaSessionInternal;
  transferPatientToCarePathway: WardPatientInternal;
  transferPatientToWard: WardPatientInternal;
  unassignPatientFromPractice: Scalars['Boolean'];
  undoExpireIntegrationApiKey: Scalars['Boolean'];
  unenrollFromTwoFactorAuth: Scalars['Boolean'];
  unenrollMyTwoFactorAuth: Scalars['Boolean'];
  updateAlertRule?: Maybe<AlertRuleInternal>;
  updateCarePathway: CarePathwayInternal;
  updateCheckupType: CheckupTypeInternal;
  updateGlobalStateDefaultFeature?: Maybe<Scalars['Boolean']>;
  updateMe: Scalars['Boolean'];
  updateOrganization: OrganizationInternal;
  updatePatient: PatientInternal;
  updatePatientContactDetails: PatientInternal;
  updateUser: UserInternal;
  updateUserOrganization: UserOrganizationInternal;
  updateWard: WardInternal;
};


export type MutationAddCheckupTypeArgsInternal = {
  checkupType: CheckupTypeInputInternal;
  pathwayId: Scalars['ID'];
};


export type MutationAddPatientNoteArgsInternal = {
  PatientId: Scalars['ID'];
  text: Scalars['String'];
};


export type MutationAddQuicksilvaIdentityArgsInternal = {
  accessCode: Scalars['String'];
};


export type MutationAddToWatchListArgsInternal = {
  patientId: Scalars['ID'];
};


export type MutationAdmitPatientToWardArgsInternal = {
  carePathwayId: Scalars['ID'];
  patientId: Scalars['ID'];
  wardId: Scalars['ID'];
};


export type MutationAssignPatientToPracticeArgsInternal = {
  patientId: Scalars['ID'];
  practiceId: Scalars['ID'];
};


export type MutationAssignStaffToWardArgsInternal = {
  userId: Scalars['ID'];
  wardId: Scalars['ID'];
};


export type MutationCaptureMetricAlgorithmExecutionArgsInternal = {
  checkupId: Scalars['ID'];
  metricAlgorithmExecution: MetricAlgorithmExecutionInputInternal;
  patientId: Scalars['ID'];
};


export type MutationCaptureMetricAppActionArgsInternal = {
  checkupId?: InputMaybe<Scalars['ID']>;
  metricAppAction: MetricAppActionInputInternal;
  patientId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateAlertRuleArgsInternal = {
  carePathwayId?: InputMaybe<Scalars['ID']>;
  condition: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
  patientId?: InputMaybe<Scalars['ID']>;
  type: Scalars['String'];
};


export type MutationCreateCarePathwayArgsInternal = {
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
  published?: InputMaybe<Scalars['Boolean']>;
  templateId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateCheckupConfigArgsInternal = {
  config: OrganizationCheckupConfigInputInternal;
  organizationId: Scalars['ID'];
  patientId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateEwsThresholdsArgsInternal = {
  patientId: Scalars['ID'];
  thresholds?: InputMaybe<ThresholdsInputInternal>;
};


export type MutationCreateEhrIntegrationArgsInternal = {
  carePathwayId: Scalars['ID'];
  ehrIntegration: CreateEhrIntegrationInputInternal;
};


export type MutationCreateFirebaseUserArgsInternal = {
  userId: Scalars['ID'];
};


export type MutationCreateOrganizationArgsInternal = {
  organization: OrganizationInputInternal;
};


export type MutationCreateOrganizationAndFirstUserArgsInternal = {
  organization: OrganizationInputInternal;
  user: UserInputInternal;
};


export type MutationCreatePatientArgsInternal = {
  patient: CreatePatientInputInternal;
};


export type MutationCreatePracticeArgsInternal = {
  organization: PracticeInputInternal;
  user: UserInputInternal;
};


export type MutationCreateSelfCareLoginArgsInternal = {
  patientId: Scalars['ID'];
  selfCare: SelfCareInputInternal;
};


export type MutationCreateUserArgsInternal = {
  jobRole?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  portalUrl?: InputMaybe<Scalars['String']>;
  role: Scalars['String'];
  user: UserInputInternal;
};


export type MutationCreateVideoCallRoomArgsInternal = {
  patientId: Scalars['ID'];
};


export type MutationCreateWardArgsInternal = {
  name: Scalars['String'];
};


export type MutationDeleteAlertRuleArgsInternal = {
  id: Scalars['ID'];
};


export type MutationDeleteCheckupScheduleArgsInternal = {
  checkupScheduleId: Scalars['ID'];
};


export type MutationDeleteCheckupTypeArgsInternal = {
  checkupTypeId: Scalars['ID'];
};


export type MutationDeleteEhrIntegrationArgsInternal = {
  ehrIntegrationId: Scalars['ID'];
};


export type MutationDeletePatientArgsInternal = {
  patientId: Scalars['ID'];
};


export type MutationDeleteUserArgsInternal = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationDeleteWardArgsInternal = {
  id: Scalars['ID'];
};


export type MutationDischargePatientFromWardArgsInternal = {
  patientId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
  wardId: Scalars['ID'];
};


export type MutationEndContinuousMonitoringSessionArgsInternal = {
  patientId: Scalars['ID'];
};


export type MutationEndPacsanaSessionArgsInternal = {
  patientId: Scalars['ID'];
};


export type MutationExpireIntegrationApiKeyArgsInternal = {
  at?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
};


export type MutationLoginAsArgsInternal = {
  userId: Scalars['ID'];
};


export type MutationPasswordResetUrlArgsInternal = {
  organizationId: Scalars['ID'];
  portalUrl: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationPreviewCheckupAlertRuleArgsInternal = {
  carePathwayId?: InputMaybe<Scalars['ID']>;
  condition: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
  patientId?: InputMaybe<Scalars['ID']>;
};


export type MutationPreviewContinuousMonitoringAlertRuleArgsInternal = {
  carePathwayId?: InputMaybe<Scalars['ID']>;
  condition: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
  patientId?: InputMaybe<Scalars['ID']>;
};


export type MutationRegisterAsEhrIntegrationAutoApproverArgsInternal = {
  ehrIntegrationId: Scalars['ID'];
};


export type MutationRegisterDeviceTokenArgsInternal = {
  messagingToken: Scalars['String'];
  mobileDeviceMetadata: MobileDeviceMetadataInputInternal;
};


export type MutationRemoveEhrIntegrationAutoApproverArgsInternal = {
  ehrIntegrationId: Scalars['ID'];
};


export type MutationRemoveFromWatchListArgsInternal = {
  patientId: Scalars['ID'];
};


export type MutationRemoveStaffFromWardArgsInternal = {
  userId: Scalars['ID'];
  wardId: Scalars['ID'];
};


export type MutationRetryEhrIntegrationEventArgsInternal = {
  ehrIntegrationEventId: Scalars['ID'];
};


export type MutationSendPushNotificationArgsInternal = {
  body: Scalars['JSONObject'];
  userId: Scalars['ID'];
};


export type MutationSendWelcomeEmailArgsInternal = {
  organizationId: Scalars['ID'];
  portalUrl: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationSetAlertStatusArgsInternal = {
  id: Scalars['ID'];
  status: Scalars['String'];
};


export type MutationSetCarePathwayThresholdsArgsInternal = {
  id: Scalars['ID'];
  overwritePatients: Scalars['Boolean'];
  thresholds?: InputMaybe<ThresholdsInputInternal>;
};


export type MutationSetCheckupTypeScheduleArgsInternal = {
  checkupTypeId: Scalars['ID'];
  schedule: CheckupScheduleInputInternal;
};


export type MutationSetEhrClinicalTermMappingArgsInternal = {
  clinicalTerms: EhrClinicalTermMappingInputInternal;
};


export type MutationSetPasswordForSelfCareUserArgsInternal = {
  password: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationStartContinuousMonitoringSessionArgsInternal = {
  patientId: Scalars['ID'];
  vivalinkSubjectId: Scalars['String'];
};


export type MutationStartPacsanaSessionArgsInternal = {
  pacsanaUserName: Scalars['String'];
  patientId: Scalars['ID'];
};


export type MutationTransferPatientToCarePathwayArgsInternal = {
  carePathwayId: Scalars['ID'];
  patientId: Scalars['ID'];
};


export type MutationTransferPatientToWardArgsInternal = {
  patientId: Scalars['ID'];
  wardId: Scalars['ID'];
};


export type MutationUnassignPatientFromPracticeArgsInternal = {
  patientId: Scalars['ID'];
  practiceId: Scalars['ID'];
};


export type MutationUndoExpireIntegrationApiKeyArgsInternal = {
  id: Scalars['ID'];
};


export type MutationUnenrollFromTwoFactorAuthArgsInternal = {
  userId: Scalars['ID'];
};


export type MutationUpdateAlertRuleArgsInternal = {
  condition?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCarePathwayArgsInternal = {
  id: Scalars['ID'];
  name: Scalars['String'];
  published: Scalars['Boolean'];
};


export type MutationUpdateCheckupTypeArgsInternal = {
  checkupType: CheckupTypeUpdateInputInternal;
  checkupTypeId: Scalars['ID'];
};


export type MutationUpdateGlobalStateDefaultFeatureArgsInternal = {
  name: Scalars['String'];
  value?: InputMaybe<Scalars['JSON']>;
};


export type MutationUpdateMeArgsInternal = {
  user: MeInputInternal;
};


export type MutationUpdateOrganizationArgsInternal = {
  organization: OrganizationUpdateInputInternal;
};


export type MutationUpdatePatientArgsInternal = {
  patient: UpdatePatientInputInternal;
};


export type MutationUpdatePatientContactDetailsArgsInternal = {
  newContact: ContactDetailsInputInternal;
  patientId: Scalars['ID'];
};


export type MutationUpdateUserArgsInternal = {
  organizationId: Scalars['ID'];
  user: UserUpdateInputInternal;
};


export type MutationUpdateUserOrganizationArgsInternal = {
  jobRole?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  roles: Array<Scalars['String']>;
  userId: Scalars['ID'];
};


export type MutationUpdateWardArgsInternal = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type NewsScoringBandInputInternal = {
  high?: InputMaybe<Scalars['Float']>;
  low?: InputMaybe<Scalars['Float']>;
  score: Scalars['Int'];
};

/**
 * A restricted view of a neighbor organization.
 * Neighbor organizations are other organizations that share patients with the queried organization.
 */
export type NeighborOrganizationInternal = {
  __typename?: 'NeighborOrganization';
  address: AddressInternal;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type NhsNumberInternal = {
  __typename?: 'NhsNumber';
  id: Scalars['ID'];
  nhsNumber: Scalars['String'];
  patient: PatientInternal;
};

export type NhsNumberResponseDetailsInternal = {
  __typename?: 'NhsNumberResponseDetails';
  editable?: Maybe<Scalars['Boolean']>;
  latestRetrievalTime?: Maybe<Scalars['Date']>;
  latestStatus: NhsNumberRetrievalStatusInternal;
  nhsNumber?: Maybe<Scalars['String']>;
};

export type NhsNumberRetrievalStatusInternal = {
  __typename?: 'NhsNumberRetrievalStatus';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type OrgLevelCheckupTilesInternal = {
  __typename?: 'OrgLevelCheckupTiles';
  checkupTiles?: Maybe<Array<Scalars['String']>>;
};

export type OrganizationInternal = {
  __typename?: 'Organization';
  acceptedTerms: Scalars['Boolean'];
  address: AddressInternal;
  carePathways: Array<CarePathwayInternal>;
  checkupConfig: OrganizationCheckupConfigInternal;
  createdAt?: Maybe<Scalars['Date']>;
  defaultCarePathway?: Maybe<CarePathwayInternal>;
  defaultPracticeIds?: Maybe<Array<Scalars['ID']>>;
  deletedAt?: Maybe<Scalars['Date']>;
  ehrClinicalTermMapping?: Maybe<EhrClinicalTermMappingInternal>;
  features?: Maybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  kits: Scalars['JSONObject'];
  metadata?: Maybe<Scalars['JSONObject']>;
  name: Scalars['String'];
  needsInviteSent?: Maybe<Scalars['Boolean']>;
  /**
   * The neighbors of this organization. Neighbors are other organizations that share patients with
   * this organization.
   */
  neighbors: Array<NeighborOrganizationInternal>;
  odsCode?: Maybe<Scalars['String']>;
  roles: Array<RoleInternal>;
  telephone?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  wards: Array<WardInternal>;
};


export type OrganizationCarePathwaysArgsInternal = {
  includeShared?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationAndUserInternal = {
  __typename?: 'OrganizationAndUser';
  organization?: Maybe<OrganizationInternal>;
  user?: Maybe<UserInternal>;
};

export type OrganizationCheckupConfigInternal = {
  __typename?: 'OrganizationCheckupConfig';
  bloodPressureCuffDeviceType?: Maybe<Scalars['String']>;
  checkupConfigs?: Maybe<Array<CheckupConfigInternal>>;
  checkupTypes?: Maybe<Array<Scalars['String']>>;
  custom?: Maybe<OrgLevelCheckupTilesInternal>;
  pulseOximeterDeviceType?: Maybe<Scalars['String']>;
  questionnaire?: Maybe<Array<Scalars['String']>>;
  stethoscopeMandatory?: Maybe<Scalars['Boolean']>;
  temperatureDeviceType?: Maybe<Scalars['String']>;
};

export type OrganizationCheckupConfigInputInternal = {
  bloodPressureCuffDeviceType?: InputMaybe<Scalars['String']>;
  checkupConfigs?: InputMaybe<Array<CheckupConfigInputInternal>>;
  checkupTypes?: InputMaybe<Array<Scalars['String']>>;
  custom?: InputMaybe<CheckupTilesInputInternal>;
  pulseOximeterDeviceType?: InputMaybe<Scalars['String']>;
  questionnaire?: InputMaybe<Array<Scalars['String']>>;
  stethoscopeMandatory?: InputMaybe<Scalars['Boolean']>;
  temperatureDeviceType?: InputMaybe<Scalars['String']>;
};

export type OrganizationInputInternal = {
  address: InputAddressInternal;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  telephone?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type OrganizationPatientInternal = {
  __typename?: 'OrganizationPatient';
  organization: OrganizationInternal;
  patient: PatientInternal;
};

export type OrganizationUpdateInputInternal = {
  address?: InputMaybe<InputAddressInternal>;
  defaultCarePathwayId?: InputMaybe<Scalars['ID']>;
  features?: InputMaybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  kits?: InputMaybe<Scalars['JSONObject']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
  name?: InputMaybe<Scalars['String']>;
  odsCode?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

export type PacsanaDailyMetricsInternal = {
  __typename?: 'PacsanaDailyMetrics';
  activeMinutes: Scalars['Int'];
  activityDate: Scalars['Date'];
  exerciseMinutes: Scalars['Int'];
  gaitSpeed?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  scores?: Maybe<PacsanaScoresInternal>;
};

export type PacsanaDailyMetricsMetadataInternal = {
  __typename?: 'PacsanaDailyMetricsMetadata';
  earliestAvailableMetrics?: Maybe<Scalars['Date']>;
  latestAvailableMetrics?: Maybe<Scalars['Date']>;
};

export type PacsanaEventInternal = {
  __typename?: 'PacsanaEvent';
  createdAt: Scalars['Date'];
  eventId: Scalars['Int'];
  eventKey: PacsanaWebhookEventIdsInternal;
  eventName: Scalars['String'];
  generatedAt: Scalars['Date'];
  id: Scalars['ID'];
  pacsanaUserUid: Scalars['String'];
  patient?: Maybe<PatientInternal>;
};

export type PacsanaKitWithActiveSessionInternal = {
  __typename?: 'PacsanaKitWithActiveSession';
  activeSession?: Maybe<PacsanaSessionInternal>;
  kitId: Scalars['String'];
};

export type PacsanaScoresInternal = {
  __typename?: 'PacsanaScores';
  gaitSpeedScore: Scalars['Int'];
};

export type PacsanaSessionInternal = {
  __typename?: 'PacsanaSession';
  createdAt: Scalars['Date'];
  endedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  organization: OrganizationInternal;
  pacsanaUserName: Scalars['String'];
  patient: PatientInternal;
  status: PacsanaSessionStatusInternal;
};

export type PacsanaSessionStatusInternal = {
  __typename?: 'PacsanaSessionStatus';
  batteryStatus?: Maybe<BatteryStatusInternal>;
  gatewayStatus?: Maybe<GatewayStatusInternal>;
  latestEventDate?: Maybe<Scalars['Date']>;
  latestMetricsDate?: Maybe<Scalars['Date']>;
  noMetricsForPreviousDay: Scalars['Boolean'];
  zeroesAcrossMetricsForPreviousDay: Scalars['Boolean'];
};

export enum PacsanaWebhookEventIdsInternal {
  AlertButtonInternal = 'AlertButton',
  AlertRecoveryInternal = 'AlertRecovery',
  BraceletBatteryInternal = 'BraceletBattery',
  BraceletInactiveAlertInternal = 'BraceletInactiveAlert',
  FallDetectionInternal = 'FallDetection',
  InTheBedroomForTwentyFourHoursInternal = 'InTheBedroomForTwentyFourHours',
  LostConnectionInternal = 'LostConnection',
  LowActiveMinutesInternal = 'LowActiveMinutes',
  SleepLessThanFiveHoursInternal = 'SleepLessThanFiveHours',
  SleepMoreThanTenHoursInternal = 'SleepMoreThanTenHours',
  StepIncreaseInDaytimeRestInternal = 'StepIncreaseInDaytimeRest',
  StepReductionInBedroomHoursInternal = 'StepReductionInBedroomHours',
  ThirtyMinuteNightTimeAbsenceInternal = 'ThirtyMinuteNightTimeAbsence',
  TwelveHoursRestDuringDaytimeInternal = 'TwelveHoursRestDuringDaytime',
  TwentyFourHourAbsenceInternal = 'TwentyFourHourAbsence',
  UnknownEventIdInternal = 'UnknownEventId',
  UserInactiveAlertInternal = 'UserInactiveAlert'
}

export type PatientInternal = {
  __typename?: 'Patient';
  activityMonitoringSession?: Maybe<PacsanaSessionInternal>;
  address?: Maybe<AddressInternal>;
  admissionEvents: Array<AdmissionEventInternal>;
  agreedAt: Scalars['Date'];
  agreedDataProcessing: Scalars['Boolean'];
  agreedDataSharing: Scalars['Boolean'];
  agreedDataTransfer: Scalars['Boolean'];
  alerts: Array<AlertInternal>;
  birthDate: Scalars['Date'];
  createdAt: Scalars['Date'];
  createdUsingAppVersion: Scalars['String'];
  deletedAt?: Maybe<Scalars['Date']>;
  ewsThresholds?: Maybe<EwsThresholdsInternal>;
  firstName: Scalars['String'];
  gender: GenderInternal;
  id: Scalars['ID'];
  isTestPatient: Scalars['Boolean'];
  lastName: Scalars['String'];
  latestCheckup?: Maybe<CheckupInternal>;
  latestContinuousMonitoring?: Maybe<ContinuousMonitoringWithSessionInternal>;
  latestContinuousMonitoringSession?: Maybe<ContinuousMonitoringSessionInternal>;
  nhsNumber?: Maybe<Scalars['String']>;
  nhsNumberResponseDetails?: Maybe<NhsNumberResponseDetailsInternal>;
  notes: Array<PatientNoteInternal>;
  numSimilarNames: Scalars['Int'];
  onWatchList: Scalars['Boolean'];
  organizations: Array<OrganizationInternal>;
  preExistingConditions?: Maybe<Scalars['String']>;
  selfCare?: Maybe<UserInternal>;
  softSignsSummary: SoftSignsSummaryInternal;
  telephone?: Maybe<Scalars['String']>;
  type: PatientTypeInternal;
  updatedAt: Scalars['Date'];
  vitalsSummary: VitalsSummaryInternal;
  wardAdmission?: Maybe<WardAdmissionInternal>;
  wardAdmissionForOrganization?: Maybe<WardAdmissionInternal>;
  wards: Array<WardInternal>;
};


export type PatientAlertsArgsInternal = {
  aggregate?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
};


export type PatientNotesArgsInternal = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type PatientOrganizationsArgsInternal = {
  type?: InputMaybe<Scalars['String']>;
};


export type PatientSoftSignsSummaryArgsInternal = {
  endDate: Scalars['Date'];
  intervalHours?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['Date'];
};


export type PatientVitalsSummaryArgsInternal = {
  endDate: Scalars['Date'];
  intervalHours?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['Date'];
};


export type PatientWardAdmissionForOrganizationArgsInternal = {
  organizationId: Scalars['ID'];
};

export type PatientLookupInputInternal = {
  birthDate: Scalars['Date'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nhsNumber?: InputMaybe<Scalars['String']>;
};

export type PatientNoteInternal = {
  __typename?: 'PatientNote';
  checkup?: Maybe<CheckupInternal>;
  createdAt: Scalars['Date'];
  createdBy?: Maybe<UserInternal>;
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  organization?: Maybe<OrganizationInternal>;
  patient: PatientInternal;
  text: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum PatientTypeInternal {
  ChildInternal = 'child',
  ElderlyInternal = 'elderly'
}

export type PictureInternal = {
  __typename?: 'Picture';
  caption?: Maybe<Scalars['String']>;
  checkup: CheckupInternal;
  id: Scalars['ID'];
  location: Scalars['String'];
};

export type PracticeInputInternal = {
  address: InputAddressInternal;
  name: Scalars['String'];
  telephone?: InputMaybe<Scalars['String']>;
};

export type PulseOximeterTileConfigInternal = CheckupTileConfigInternal & {
  __typename?: 'PulseOximeterTileConfig';
  manualEntry?: Maybe<Scalars['Boolean']>;
  rule: CheckupTileRuleInternal;
  usePlethAI?: Maybe<Scalars['Boolean']>;
};

export type PulseRateInternal = {
  __typename?: 'PulseRate';
  isManual: Scalars['Boolean'];
  source: PulseRateSourceInternal;
  value: Scalars['Int'];
};

export enum PulseRateSourceInternal {
  BpCuffInternal = 'bpCuff',
  PulseOximeterInternal = 'pulseOximeter'
}

export type QueryInternal = {
  __typename?: 'Query';
  actionLogs: ActionLogsInternal;
  alertRules?: Maybe<Array<AlertRuleInternal>>;
  alerts: Array<AlertInternal>;
  carePathway: CarePathwayInternal;
  carePathwayTemplates: Array<CarePathwayTemplateInternal>;
  carePathways: Array<CarePathwayInternal>;
  checkup?: Maybe<CheckupInternal>;
  checkupsByPatient: Array<CheckupInternal>;
  continuousMonitoring: ContinuousMonitoringPagesInternal;
  continuousMonitoringSession?: Maybe<ContinuousMonitoringSessionInternal>;
  ehrIntegrationEvents: EhrIntegrationEventsInternal;
  ewsThresholds?: Maybe<EwsThresholdsInternal>;
  globalState?: Maybe<Array<GlobalStateInternal>>;
  integrationApiKeys: Array<IntegrationApiKeyInternal>;
  isAuthorized: Scalars['Boolean'];
  lookupPatient?: Maybe<PatientInternal>;
  me?: Maybe<MeInternal>;
  organization: OrganizationInternal;
  organizations?: Maybe<Array<OrganizationInternal>>;
  pacsanaDailyMetricsForPatient: Array<PacsanaDailyMetricsInternal>;
  pacsanaDailyMetricsMetadata?: Maybe<PacsanaDailyMetricsMetadataInternal>;
  pacsanaKitsWithActiveSession: Array<PacsanaKitWithActiveSessionInternal>;
  pacsanaSession?: Maybe<PacsanaSessionInternal>;
  patient: PatientInternal;
  patients: Array<PatientInternal>;
  patientsInWatchList: Array<PatientInternal>;
  practices: Array<OrganizationInternal>;
  recommendedMobileVersion?: Maybe<Scalars['String']>;
  searchPatients: Array<PatientInternal>;
  shareTokens: Array<ShareTokenInternal>;
  uninvitedPracticesCount?: Maybe<Scalars['Int']>;
  userOrganizations: Array<UserOrganizationInternal>;
  users: Array<Maybe<UserInternal>>;
  version?: Maybe<Scalars['String']>;
  virtualWardPatients: VirtualWardPatientsInternal;
  vivalinkKitsWithActiveSession: Array<VivalinkKitWithActiveSessionInternal>;
  vivalinkTroubleshooting?: Maybe<Array<VivalinkSensorInternal>>;
  ward: WardInternal;
  wards: Array<WardInternal>;
};


export type QueryActionLogsArgsInternal = {
  fromActionLogId?: InputMaybe<Scalars['ID']>;
  patientId: Scalars['ID'];
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryAlertRulesArgsInternal = {
  carePathwayId?: InputMaybe<Scalars['ID']>;
};


export type QueryAlertsArgsInternal = {
  alertRuleId?: InputMaybe<Scalars['ID']>;
  checkupScheduleId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  includeRecentlyClosed?: InputMaybe<Scalars['Boolean']>;
  pacsanaEventId?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryCarePathwayArgsInternal = {
  id: Scalars['ID'];
};


export type QueryCarePathwayTemplatesArgsInternal = {
  organizationId: Scalars['ID'];
};


export type QueryCarePathwaysArgsInternal = {
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  includeShared?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['ID']>;
};


export type QueryCheckupArgsInternal = {
  id: Scalars['ID'];
};


export type QueryCheckupsByPatientArgsInternal = {
  endDate?: InputMaybe<Scalars['Date']>;
  patientId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryContinuousMonitoringArgsInternal = {
  currentBucketStartAt?: InputMaybe<Scalars['Date']>;
  numPrevBuckets: Scalars['Int'];
  patientId: Scalars['ID'];
};


export type QueryContinuousMonitoringSessionArgsInternal = {
  patientId: Scalars['ID'];
};


export type QueryEhrIntegrationEventsArgsInternal = {
  carePathwayId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<EhrIntegrationEventQuerySortInternal>;
  status?: InputMaybe<Array<EhrIntegrationStatusInternal>>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryEwsThresholdsArgsInternal = {
  at?: InputMaybe<Scalars['Date']>;
  patientId: Scalars['ID'];
};


export type QueryGlobalStateArgsInternal = {
  key?: InputMaybe<Scalars['String']>;
};


export type QueryIsAuthorizedArgsInternal = {
  permission: Scalars['String'];
};


export type QueryLookupPatientArgsInternal = {
  fields: PatientLookupInputInternal;
};


export type QueryOrganizationArgsInternal = {
  id: Scalars['ID'];
};


export type QueryOrganizationsArgsInternal = {
  showDeleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPacsanaDailyMetricsForPatientArgsInternal = {
  endDate?: InputMaybe<Scalars['Date']>;
  patientId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryPacsanaDailyMetricsMetadataArgsInternal = {
  patientId: Scalars['ID'];
};


export type QueryPacsanaSessionArgsInternal = {
  patientId: Scalars['ID'];
};


export type QueryPatientArgsInternal = {
  id: Scalars['ID'];
};


export type QueryPatientsArgsInternal = {
  isTestPatient?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchPatientsArgsInternal = {
  includeDeleted: Scalars['Boolean'];
  organizationId: Scalars['ID'];
  query: Scalars['String'];
};


export type QueryUserOrganizationsArgsInternal = {
  organizationId: Scalars['ID'];
  showDeleted: Scalars['Boolean'];
};


export type QueryUsersArgsInternal = {
  organizationId?: InputMaybe<Scalars['ID']>;
};


export type QueryVirtualWardPatientsArgsInternal = {
  isTestPatient?: InputMaybe<Scalars['Boolean']>;
  nameOrNhsNumber?: InputMaybe<Scalars['String']>;
  neighborIds?: InputMaybe<Array<Scalars['ID']>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<VirtualWardPatientsSortInputInternal>>>;
  take?: InputMaybe<Scalars['Int']>;
  wardIds?: InputMaybe<Array<Scalars['ID']>>;
  withCheckupsInLastHours?: InputMaybe<Scalars['Int']>;
};


export type QueryVivalinkTroubleshootingArgsInternal = {
  kitId: Scalars['ID'];
};


export type QueryWardArgsInternal = {
  id: Scalars['ID'];
};

export enum QuestionnaireSectionTypeInternal {
  BarthelActivitiesDailyLivingInternal = 'BARTHEL_Activities_Daily_Living',
  HolywellUnwellPatient_20240415Internal = 'Holywell_UnwellPatient_20240415',
  JpuhAsthma_20231124Internal = 'JPUH_Asthma_20231124',
  JpuhFrailty_20231124Internal = 'JPUH_Frailty_20231124',
  JpuhHeartFailure_20231124Internal = 'JPUH_Heart_Failure_20231124',
  JpuhLiverDecomp_20240823Internal = 'JPUH_Liver_Decomp_20240823',
  JpuhOsteomyelitis_20240102Internal = 'JPUH_Osteomyelitis_20240102',
  JpuhPneumonia_20240102Internal = 'JPUH_Pneumonia_20240102',
  JpuhSurgical_20231124Internal = 'JPUH_Surgical_20231124',
  NchcCardiacCp1_20230918Internal = 'NCHC_Cardiac_CP1_20230918',
  NchcFrailtyCp1_20230703Internal = 'NCHC_Frailty_CP1_20230703',
  NchcRespiratoryCp1_20230918Internal = 'NCHC_Respiratory_CP1_20230918',
  NnuhCmCp1_20231113Internal = 'NNUH_CM_CP1_20231113',
  NnuhCardiac_20240401Internal = 'NNUH_Cardiac_20240401',
  NnuhIvTherapyCp1_20230703Internal = 'NNUH_IVTherapy_CP1_20230703',
  NnuhRespiratory_20240401Internal = 'NNUH_Respiratory_20240401',
  Phq_9EmotionalWellbeingInternal = 'PHQ_9_Emotional_Wellbeing',
  PainManagementInternal = 'Pain_Management',
  QehAki_20240415Internal = 'QEH_AKI_20240415',
  QehCellulitis_20231012Internal = 'QEH_Cellulitis_20231012',
  QehHeartFailure_20240110Internal = 'QEH_HeartFailure_20240110',
  QehLrti_1y_4yInternal = 'QEH_LRTI_1y_4y',
  QehLrti_5y_12yInternal = 'QEH_LRTI_5y_12y',
  QehLrti_6wk_11moInternal = 'QEH_LRTI_6wk_11mo',
  QehPaedsGastro_20240429Internal = 'QEH_Paeds_Gastro_20240429',
  QehPaedsGeneric_6mo_18yInternal = 'QEH_Paeds_Generic_6mo_18y',
  QehPaedsWheeze_6mo_18yLtri_13y_18yInternal = 'QEH_Paeds_Wheeze_6mo_18y_LTRI_13y_18y',
  EmergencyInternal = 'emergency',
  GastrointestinalInternal = 'gastrointestinal',
  GeneralInternal = 'general',
  NervousInternal = 'nervous',
  RespiratoryInternal = 'respiratory'
}

export type RespiratoryRateInternal = {
  __typename?: 'RespiratoryRate';
  source?: Maybe<RespiratoryRateSourceInternal>;
  value: Scalars['Int'];
};

export enum RespiratoryRateSourceInternal {
  ManualInternal = 'manual',
  MasimoInternal = 'masimo',
  PlethAiInternal = 'plethAI'
}

export type RoleInternal = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']>;
  isSystemRole: Scalars['Boolean'];
  name: Scalars['String'];
};

export type SelfCareInputInternal = {
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type ShareTokenInternal = {
  __typename?: 'ShareToken';
  createdAt: Scalars['Date'];
  expiresAt: Scalars['Date'];
  id: Scalars['ID'];
  patient: PatientInternal;
  user: UserInternal;
};

export type SoftSignInternal = {
  __typename?: 'SoftSign';
  isEmergency: Scalars['Boolean'];
  key: Scalars['String'];
};

export type SoftSignCodingInternal = {
  __typename?: 'SoftSignCoding';
  code: Scalars['String'];
  softSign: Scalars['String'];
};

export type SoftSignsCountInternal = {
  __typename?: 'SoftSignsCount';
  count: Scalars['Int'];
  softSign: SoftSignInternal;
};

export type SoftSignsSummaryInternal = {
  __typename?: 'SoftSignsSummary';
  frames: Array<SoftSignsSummaryFrameInternal>;
};

export type SoftSignsSummaryFrameInternal = {
  __typename?: 'SoftSignsSummaryFrame';
  end: Scalars['Date'];
  start: Scalars['Date'];
  values: Array<SoftSignsCountInternal>;
};

export enum SortDirectionInternal {
  AscInternal = 'ASC',
  DescInternal = 'DESC'
}

export type StethoscopeTileConfigInternal = CheckupTileConfigInternal & {
  __typename?: 'StethoscopeTileConfig';
  forceOnAbnormalRespiratoryRate?: Maybe<Scalars['Boolean']>;
  forceOnAbnormalSpO2?: Maybe<Scalars['Boolean']>;
  forceOnRespiratorySoftSigns?: Maybe<Scalars['Boolean']>;
  rule: CheckupTileRuleInternal;
};

export type SymptomsQuestionnaireTileConfigInternal = CheckupTileConfigInternal & {
  __typename?: 'SymptomsQuestionnaireTileConfig';
  questionnaireSections?: Maybe<Array<QuestionnaireSectionTypeInternal>>;
  rule: CheckupTileRuleInternal;
};

export type ThresholdsInputInternal = {
  bloodPressure: Array<NewsScoringBandInputInternal>;
  gaitSpeed: Array<NewsScoringBandInputInternal>;
  pulse: Array<NewsScoringBandInputInternal>;
  respiratoryRate: Array<NewsScoringBandInputInternal>;
  spO2: Array<NewsScoringBandInputInternal>;
  temperature: Array<NewsScoringBandInputInternal>;
};

export type UpdatePatientInputInternal = {
  address?: InputMaybe<InputAddressInternal>;
  birthDate?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  nhsNumber?: InputMaybe<Scalars['String']>;
  preExistingConditions?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

export type UserInternal = UserInterfaceInternal & {
  __typename?: 'User';
  canReceivePushNotifications: Scalars['Boolean'];
  canResetPassword: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  enrolledInTotpMfa: Scalars['Boolean'];
  firebaseUid?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** For the current acting organization, is this user an active team member? */
  isActiveTeamMember: Scalars['Boolean'];
  isFeebroid?: Maybe<Scalars['Boolean']>;
  isQuicksilvaIdentitySetup: Scalars['Boolean'];
  isSelfCare: Scalars['Boolean'];
  /** The role the user takes in organizations, distinct from Feebris role */
  jobRoles: Array<JobRoleInternal>;
  lastName?: Maybe<Scalars['String']>;
  organizations: Array<OrganizationInternal>;
  updatedAt?: Maybe<Scalars['Date']>;
  wards: Array<WardInternal>;
};

export type UserDeviceInternal = {
  __typename?: 'UserDevice';
  createdAt: Scalars['Date'];
  deviceId: Scalars['ID'];
  name: Scalars['String'];
  os: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type UserInputInternal = {
  email: Scalars['String'];
  firebaseUid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type UserInterfaceInternal = {
  canReceivePushNotifications: Scalars['Boolean'];
  canResetPassword: Scalars['Boolean'];
  email: Scalars['String'];
  enrolledInTotpMfa: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isQuicksilvaIdentitySetup: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  organizations: Array<OrganizationInternal>;
  wards: Array<WardInternal>;
};

export type UserOrganizationInternal = {
  __typename?: 'UserOrganization';
  createdAt: Scalars['Date'];
  jobRole?: Maybe<Scalars['String']>;
  lastLoggedInAt?: Maybe<Scalars['Date']>;
  organization: OrganizationInternal;
  roles: Array<Maybe<Scalars['String']>>;
  updatedAt: Scalars['Date'];
  user: UserInternal;
};

export type UserUpdateInputInternal = {
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
};

export type VideoCallDetailsInternal = {
  __typename?: 'VideoCallDetails';
  hostRoomUrl: Scalars['String'];
  patientRoomUrl: Scalars['String'];
  sentPushNotification: Scalars['Boolean'];
  sentSms: Scalars['Boolean'];
};

export type VirtualWardPatientsInternal = {
  __typename?: 'VirtualWardPatients';
  patients: Array<PatientInternal>;
  total: Scalars['Int'];
};

export enum VirtualWardPatientsSortFieldInternal {
  AlertCountInternal = 'alertCount',
  EwsScoreInternal = 'ewsScore',
  LatestVitalsInternal = 'latestVitals'
}

export type VirtualWardPatientsSortInputInternal = {
  direction: SortDirectionInternal;
  field: VirtualWardPatientsSortFieldInternal;
};

export type VitalAggregateInternal = {
  __typename?: 'VitalAggregate';
  max: Scalars['Float'];
  median: Scalars['Float'];
  min: Scalars['Float'];
};

export type VitalCodingInternal = {
  __typename?: 'VitalCoding';
  code: Scalars['String'];
  unitText?: Maybe<Scalars['String']>;
  vital: CodedVitalInternal;
};

export type VitalsAggregatesInternal = {
  __typename?: 'VitalsAggregates';
  diastolicBloodPressure?: Maybe<VitalAggregateInternal>;
  glucose?: Maybe<VitalAggregateInternal>;
  pulseRate?: Maybe<VitalAggregateInternal>;
  respiratoryRate?: Maybe<VitalAggregateInternal>;
  spO2?: Maybe<VitalAggregateInternal>;
  systolicBloodPressure?: Maybe<VitalAggregateInternal>;
  temperature?: Maybe<VitalAggregateInternal>;
  weight?: Maybe<VitalAggregateInternal>;
};

export type VitalsSummaryInternal = {
  __typename?: 'VitalsSummary';
  frames: Array<VitalsSummaryFrameInternal>;
};

export type VitalsSummaryFrameInternal = {
  __typename?: 'VitalsSummaryFrame';
  end: Scalars['Date'];
  start: Scalars['Date'];
  values: VitalsAggregatesInternal;
};

export type VivalinkKitWithActiveSessionInternal = {
  __typename?: 'VivalinkKitWithActiveSession';
  activeSession?: Maybe<ContinuousMonitoringSessionInternal>;
  kitId: Scalars['String'];
};

export type VivalinkSensorInternal = {
  __typename?: 'VivalinkSensor';
  lastActive?: Maybe<Scalars['Date']>;
  sensorId: Scalars['ID'];
  sensorType?: Maybe<VivalinkSensorTypesInternal>;
  topOne?: Maybe<VivalinkTopOneInternal>;
};

export type VivalinkSensorDataInternal = {
  __typename?: 'VivalinkSensorData';
  ecg?: Maybe<ContinuousMonitoringInternal>;
  spo2?: Maybe<ContinuousMonitoringInternal>;
  temperature?: Maybe<ContinuousMonitoringInternal>;
};

export enum VivalinkSensorTypesInternal {
  EcgInternal = 'ecg',
  Spo2Internal = 'spo2',
  TemperatureInternal = 'temperature'
}

export type VivalinkTopOneInternal = {
  __typename?: 'VivalinkTopOne';
  patchBattery: Scalars['Float'];
  recordTime: Scalars['Date'];
};

export type WardInternal = {
  __typename?: 'Ward';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  numberOfPatients: Scalars['Int'];
  numberOfStaff: Scalars['Int'];
  organization: OrganizationInternal;
  updatedAt: Scalars['Date'];
  wardPatients: Array<WardPatientInternal>;
  wardStaff: Array<WardStaffInternal>;
};

export type WardAdmissionInternal = {
  __typename?: 'WardAdmission';
  admittedAt: Scalars['Date'];
  carePathway?: Maybe<CarePathwayInternal>;
  ward: WardInternal;
};

export type WardPatientInternal = {
  __typename?: 'WardPatient';
  admittedAt: Scalars['Date'];
  carePathway?: Maybe<CarePathwayInternal>;
  patient: PatientInternal;
};

export type WardStaffInternal = {
  __typename?: 'WardStaff';
  assignedAt: Scalars['Date'];
  user: UserInternal;
};

export type CreateCarePathwayMutationVariablesInternal = Exact<{
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
  templateId?: InputMaybe<Scalars['ID']>;
  published: Scalars['Boolean'];
}>;


export type CreateCarePathwayMutationInternal = (
  { __typename?: 'Mutation' }
  & { createCarePathway: (
    { __typename?: 'CarePathway' }
    & Pick<CarePathwayInternal, 'id' | 'name' | 'createdAt' | 'publishedAt'>
  ) }
);

export type AddCheckupTypeMutationVariablesInternal = Exact<{
  pathwayId: Scalars['ID'];
  checkupType: CheckupTypeInputInternal;
}>;


export type AddCheckupTypeMutationInternal = (
  { __typename?: 'Mutation' }
  & { addCheckupType: (
    { __typename?: 'CheckupType' }
    & Pick<CheckupTypeInternal, 'id' | 'name' | 'createdAt'>
  ) }
);

export type AdminAlertRulesItemFragmentInternal = (
  { __typename?: 'AlertRule' }
  & Pick<AlertRuleInternal, 'id' | 'name' | 'type' | 'description' | 'condition' | 'updatedAt'>
);

export type GetAlertRulesQueryVariablesInternal = Exact<{
  carePathwayId: Scalars['ID'];
}>;


export type GetAlertRulesQueryInternal = (
  { __typename?: 'Query' }
  & { alertRules?: Maybe<Array<(
    { __typename?: 'AlertRule' }
    & AdminAlertRulesItemFragmentInternal
  )>> }
);

export type AdminCheckupTypeItemFragmentInternal = (
  { __typename?: 'CheckupType' }
  & Pick<CheckupTypeInternal, 'id' | 'name' | 'heading' | 'subheading' | 'description' | 'createdAt' | 'updatedAt'>
  & { schedule?: Maybe<(
    { __typename?: 'CheckupSchedule' }
    & Pick<CheckupScheduleInternal, 'rrule'>
    & { tolerance: (
      { __typename?: 'CheckupScheduleTolerance' }
      & Pick<CheckupScheduleToleranceInternal, 'early' | 'late'>
    ) }
  )>, checkupTiles: (
    { __typename?: 'CheckupTiles' }
    & { symptomsQuestionnaire?: Maybe<(
      { __typename?: 'SymptomsQuestionnaireTileConfig' }
      & Pick<SymptomsQuestionnaireTileConfigInternal, 'rule' | 'questionnaireSections'>
    )>, consciousness?: Maybe<(
      { __typename?: 'GenericCheckupTileConfig' }
      & Pick<GenericCheckupTileConfigInternal, 'rule'>
    )>, pulseOximeter?: Maybe<(
      { __typename?: 'PulseOximeterTileConfig' }
      & Pick<PulseOximeterTileConfigInternal, 'rule' | 'manualEntry' | 'usePlethAI'>
    )>, respiratoryRate?: Maybe<(
      { __typename?: 'GenericCheckupTileConfig' }
      & Pick<GenericCheckupTileConfigInternal, 'rule'>
    )>, bloodPressureCuff?: Maybe<(
      { __typename?: 'BloodPressureCuffTileConfig' }
      & Pick<BloodPressureCuffTileConfigInternal, 'rule' | 'manualEntry'>
    )>, temperature?: Maybe<(
      { __typename?: 'GenericCheckupTileConfig' }
      & Pick<GenericCheckupTileConfigInternal, 'rule'>
    )>, stethoscope?: Maybe<(
      { __typename?: 'StethoscopeTileConfig' }
      & Pick<StethoscopeTileConfigInternal, 'rule' | 'forceOnAbnormalSpO2' | 'forceOnRespiratorySoftSigns' | 'forceOnAbnormalRespiratoryRate'>
    )>, picture?: Maybe<(
      { __typename?: 'GenericCheckupTileConfig' }
      & Pick<GenericCheckupTileConfigInternal, 'rule'>
    )>, weight?: Maybe<(
      { __typename?: 'GenericCheckupTileConfig' }
      & Pick<GenericCheckupTileConfigInternal, 'rule'>
    )>, glucose?: Maybe<(
      { __typename?: 'GenericCheckupTileConfig' }
      & Pick<GenericCheckupTileConfigInternal, 'rule'>
    )> }
  ) }
);

export type CarePathwayAdminQueryVariablesInternal = Exact<{
  id: Scalars['ID'];
}>;


export type CarePathwayAdminQueryInternal = (
  { __typename?: 'Query' }
  & { carePathway: (
    { __typename?: 'CarePathway' }
    & Pick<CarePathwayInternal, 'name' | 'publishedAt' | 'deletedAt'>
    & { ewsThresholds?: Maybe<(
      { __typename?: 'EWSThresholds' }
      & Pick<EwsThresholdsInternal, 'thresholds'>
    )>, checkupTypes: Array<(
      { __typename?: 'CheckupType' }
      & AdminCheckupTypeItemFragmentInternal
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<OrganizationInternal, 'id'>
    )> }
  ) }
);

export type AdminCarePathwayItemFragmentInternal = (
  { __typename?: 'CarePathway' }
  & Pick<CarePathwayInternal, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'publishedAt' | 'deletedAt'>
  & { numberOfActivePatients: CarePathwayInternal['numberOfPatients'], totalNumberOfAdmissions: CarePathwayInternal['numberOfPatients'] }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<OrganizationInternal, 'id'>
  )> }
);

export type OrganizationCarePathwaysQueryVariablesInternal = Exact<{
  organizationId: Scalars['ID'];
  includeDeleted: Scalars['Boolean'];
}>;


export type OrganizationCarePathwaysQueryInternal = (
  { __typename?: 'Query' }
  & { carePathways: Array<(
    { __typename?: 'CarePathway' }
    & AdminCarePathwayItemFragmentInternal
  )> }
);

export type AdminCarePathwayTemplateItemFragmentInternal = (
  { __typename?: 'CarePathwayTemplate' }
  & Pick<CarePathwayTemplateInternal, 'id' | 'name'>
);

export type OrganizationCarePathwayTemplatesQueryVariablesInternal = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganizationCarePathwayTemplatesQueryInternal = (
  { __typename?: 'Query' }
  & { carePathwayTemplates: Array<(
    { __typename?: 'CarePathwayTemplate' }
    & AdminCarePathwayTemplateItemFragmentInternal
  )> }
);

export type PreviewCheckupAlertRuleMutationVariablesInternal = Exact<{
  condition: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
}>;


export type PreviewCheckupAlertRuleMutationInternal = (
  { __typename?: 'Mutation' }
  & { previewCheckupAlertRule?: Maybe<(
    { __typename?: 'AlertCheckupRulePreview' }
    & Pick<AlertCheckupRulePreviewInternal, 'condition' | 'percentTriggered' | 'numTested'>
    & { examples: Array<(
      { __typename?: 'Checkup' }
      & Pick<CheckupInternal, 'id' | 'endedAt'>
      & { patient?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<PatientInternal, 'id'>
      )> }
    )> }
  )> }
);

export type PreviewContinuousMonitoringAlertRuleMutationVariablesInternal = Exact<{
  condition: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
}>;


export type PreviewContinuousMonitoringAlertRuleMutationInternal = (
  { __typename?: 'Mutation' }
  & { previewContinuousMonitoringAlertRule?: Maybe<(
    { __typename?: 'AlertContinuousMonitoringRulePreview' }
    & Pick<AlertContinuousMonitoringRulePreviewInternal, 'condition' | 'percentTriggered' | 'numTested'>
    & { examples: Array<(
      { __typename?: 'ContinuousMonitoring' }
      & Pick<ContinuousMonitoringInternal, 'id' | 'bucketStartAt'>
      & { patient?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<PatientInternal, 'id'>
      )> }
    )> }
  )> }
);

export type UpdateAlertRuleMutationVariablesInternal = Exact<{
  id: Scalars['ID'];
  type: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  condition: Scalars['String'];
}>;


export type UpdateAlertRuleMutationInternal = (
  { __typename?: 'Mutation' }
  & { updateAlertRule?: Maybe<(
    { __typename?: 'AlertRule' }
    & Pick<AlertRuleInternal, 'id'>
  )> }
);

export type CreateAlertRuleMutationVariablesInternal = Exact<{
  carePathwayId: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  description: Scalars['String'];
  condition: Scalars['String'];
}>;


export type CreateAlertRuleMutationInternal = (
  { __typename?: 'Mutation' }
  & { createAlertRule?: Maybe<(
    { __typename?: 'AlertRule' }
    & Pick<AlertRuleInternal, 'id'>
  )> }
);

export type SetCarePathwayThresholdsMutationVariablesInternal = Exact<{
  id: Scalars['ID'];
  thresholds?: InputMaybe<ThresholdsInputInternal>;
  overwritePatients: Scalars['Boolean'];
}>;


export type SetCarePathwayThresholdsMutationInternal = (
  { __typename?: 'Mutation' }
  & { setCarePathwayThresholds: (
    { __typename?: 'CarePathway' }
    & Pick<CarePathwayInternal, 'id'>
  ) }
);

export type UpdateCarePathwayMutationVariablesInternal = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  published: Scalars['Boolean'];
}>;


export type UpdateCarePathwayMutationInternal = (
  { __typename?: 'Mutation' }
  & { updateCarePathway: (
    { __typename?: 'CarePathway' }
    & Pick<CarePathwayInternal, 'id' | 'name' | 'createdAt' | 'publishedAt'>
  ) }
);

export type SetCheckupTypeScheduleMutationVariablesInternal = Exact<{
  checkupTypeId: Scalars['ID'];
  schedule: CheckupScheduleInputInternal;
}>;


export type SetCheckupTypeScheduleMutationInternal = (
  { __typename?: 'Mutation' }
  & { setCheckupTypeSchedule: (
    { __typename?: 'CheckupSchedule' }
    & Pick<CheckupScheduleInternal, 'id'>
  ) }
);

export type UpdateCheckupTypeMutationVariablesInternal = Exact<{
  checkupTypeId: Scalars['ID'];
  checkupType: CheckupTypeUpdateInputInternal;
}>;


export type UpdateCheckupTypeMutationInternal = (
  { __typename?: 'Mutation' }
  & { updateCheckupType: (
    { __typename?: 'CheckupType' }
    & Pick<CheckupTypeInternal, 'id' | 'name' | 'createdAt'>
  ) }
);

export type DeleteAlertRuleMutationVariablesInternal = Exact<{
  alertRuleId: Scalars['ID'];
}>;


export type DeleteAlertRuleMutationInternal = (
  { __typename?: 'Mutation' }
  & Pick<MutationInternal, 'deleteAlertRule'>
);

export type DeleteCheckupTypeMutationVariablesInternal = Exact<{
  checkupTypeId: Scalars['ID'];
}>;


export type DeleteCheckupTypeMutationInternal = (
  { __typename?: 'Mutation' }
  & Pick<MutationInternal, 'deleteCheckupType'>
);

export type OrganizationAdminViewFragmentInternal = (
  { __typename?: 'Organization' }
  & Pick<OrganizationInternal, 'id' | 'name' | 'type' | 'features'>
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<RoleInternal, 'name' | 'isSystemRole'>
  )> }
);

export type OrganizationAdminQueryVariablesInternal = Exact<{
  id: Scalars['ID'];
}>;


export type OrganizationAdminQueryInternal = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'Organization' }
    & OrganizationAdminViewFragmentInternal
  ) }
);

export type AdminDefaultFeaturesQueryVariablesInternal = Exact<{ [key: string]: never; }>;


export type AdminDefaultFeaturesQueryInternal = (
  { __typename?: 'Query' }
  & { globalState?: Maybe<Array<(
    { __typename?: 'GlobalState' }
    & Pick<GlobalStateInternal, 'key' | 'value'>
  )>> }
);

export type UpdateOrganizationFeaturesMutationVariablesInternal = Exact<{
  organizationId: Scalars['ID'];
  features: Scalars['JSONObject'];
}>;


export type UpdateOrganizationFeaturesMutationInternal = (
  { __typename?: 'Mutation' }
  & { updateOrganization: (
    { __typename?: 'Organization' }
    & OrganizationAdminViewFragmentInternal
  ) }
);

export type PatientSearchItemFragmentInternal = (
  { __typename?: 'Patient' }
  & Pick<PatientInternal, 'id' | 'firstName' | 'lastName' | 'nhsNumber' | 'telephone' | 'deletedAt'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<AddressInternal, 'address' | 'postcode'>
  )>, latestContinuousMonitoringSession?: Maybe<(
    { __typename?: 'ContinuousMonitoringSession' }
    & Pick<ContinuousMonitoringSessionInternal, 'createdAt' | 'endedAt'>
    & { kitId: ContinuousMonitoringSessionInternal['vivalinkSubjectId'] }
    & { earliestBySensor?: Maybe<(
      { __typename?: 'VivalinkSensorData' }
      & { temperature?: Maybe<(
        { __typename?: 'ContinuousMonitoring' }
        & Pick<ContinuousMonitoringInternal, 'bucketEndAt'>
        & { battery?: Maybe<(
          { __typename?: 'BatteryStats' }
          & Pick<BatteryStatsInternal, 'temperature'>
        )> }
      )>, ecg?: Maybe<(
        { __typename?: 'ContinuousMonitoring' }
        & Pick<ContinuousMonitoringInternal, 'bucketEndAt'>
        & { battery?: Maybe<(
          { __typename?: 'BatteryStats' }
          & Pick<BatteryStatsInternal, 'ecg'>
        )> }
      )>, spo2?: Maybe<(
        { __typename?: 'ContinuousMonitoring' }
        & Pick<ContinuousMonitoringInternal, 'bucketEndAt'>
        & { battery?: Maybe<(
          { __typename?: 'BatteryStats' }
          & Pick<BatteryStatsInternal, 'spo2'>
        )> }
      )> }
    )>, latestBySensor?: Maybe<(
      { __typename?: 'VivalinkSensorData' }
      & { temperature?: Maybe<(
        { __typename?: 'ContinuousMonitoring' }
        & Pick<ContinuousMonitoringInternal, 'bucketEndAt'>
        & { battery?: Maybe<(
          { __typename?: 'BatteryStats' }
          & Pick<BatteryStatsInternal, 'temperature'>
        )> }
      )>, ecg?: Maybe<(
        { __typename?: 'ContinuousMonitoring' }
        & Pick<ContinuousMonitoringInternal, 'bucketEndAt'>
        & { battery?: Maybe<(
          { __typename?: 'BatteryStats' }
          & Pick<BatteryStatsInternal, 'ecg'>
        )> }
      )>, spo2?: Maybe<(
        { __typename?: 'ContinuousMonitoring' }
        & Pick<ContinuousMonitoringInternal, 'bucketEndAt'>
        & { battery?: Maybe<(
          { __typename?: 'BatteryStats' }
          & Pick<BatteryStatsInternal, 'spo2'>
        )> }
      )> }
    )> }
  )>, wardAdmissionForOrganization?: Maybe<(
    { __typename?: 'WardAdmission' }
    & Pick<WardAdmissionInternal, 'admittedAt'>
    & { ward: (
      { __typename?: 'Ward' }
      & Pick<WardInternal, 'id' | 'name'>
    ), carePathway?: Maybe<(
      { __typename?: 'CarePathway' }
      & Pick<CarePathwayInternal, 'id' | 'name'>
    )> }
  )> }
);

export type SearchPatientsQueryVariablesInternal = Exact<{
  organizationId: Scalars['ID'];
  query: Scalars['String'];
  includeDeleted: Scalars['Boolean'];
}>;


export type SearchPatientsQueryInternal = (
  { __typename?: 'Query' }
  & { searchPatients: Array<(
    { __typename?: 'Patient' }
    & PatientSearchItemFragmentInternal
  )> }
);

export type VivalinkSensorItemFragmentInternal = (
  { __typename?: 'VivalinkSensor' }
  & Pick<VivalinkSensorInternal, 'sensorId' | 'sensorType' | 'lastActive'>
  & { topOne?: Maybe<(
    { __typename?: 'VivalinkTopOne' }
    & Pick<VivalinkTopOneInternal, 'recordTime' | 'patchBattery'>
  )> }
);

export type VivalinkTroubleshootingQueryVariablesInternal = Exact<{
  kitId: Scalars['ID'];
}>;


export type VivalinkTroubleshootingQueryInternal = (
  { __typename?: 'Query' }
  & { vivalinkTroubleshooting?: Maybe<Array<(
    { __typename?: 'VivalinkSensor' }
    & VivalinkSensorItemFragmentInternal
  )>> }
);

export const AdminAlertRulesItemFragmentDocInternal = gql`
    fragment AdminAlertRulesItem on AlertRule {
  id
  name
  type
  description
  condition
  updatedAt
}
    `;
export const AdminCheckupTypeItemFragmentDocInternal = gql`
    fragment AdminCheckupTypeItem on CheckupType {
  id
  name
  heading
  subheading
  description
  schedule {
    tolerance {
      early
      late
    }
    rrule
  }
  checkupTiles {
    symptomsQuestionnaire {
      rule
      questionnaireSections
    }
    consciousness {
      rule
    }
    pulseOximeter {
      rule
      manualEntry
      usePlethAI
    }
    respiratoryRate {
      rule
    }
    bloodPressureCuff {
      rule
      manualEntry
    }
    temperature {
      rule
    }
    stethoscope {
      rule
      forceOnAbnormalSpO2
      forceOnRespiratorySoftSigns
      forceOnAbnormalRespiratoryRate
    }
    picture {
      rule
    }
    weight {
      rule
    }
    glucose {
      rule
    }
  }
  createdAt
  updatedAt
}
    `;
export const AdminCarePathwayItemFragmentDocInternal = gql`
    fragment AdminCarePathwayItem on CarePathway {
  id
  name
  createdAt
  updatedAt
  publishedAt
  deletedAt
  organization {
    id
  }
  numberOfActivePatients: numberOfPatients(includeDischarged: false)
  totalNumberOfAdmissions: numberOfPatients(includeDischarged: true)
}
    `;
export const AdminCarePathwayTemplateItemFragmentDocInternal = gql`
    fragment AdminCarePathwayTemplateItem on CarePathwayTemplate {
  id
  name
}
    `;
export const OrganizationAdminViewFragmentDocInternal = gql`
    fragment OrganizationAdminView on Organization {
  id
  name
  type
  features
  roles {
    name
    isSystemRole
  }
}
    `;
export const PatientSearchItemFragmentDocInternal = gql`
    fragment PatientSearchItem on Patient {
  id
  firstName
  lastName
  nhsNumber
  telephone
  address {
    address
    postcode
  }
  deletedAt
  latestContinuousMonitoringSession {
    kitId: vivalinkSubjectId
    createdAt
    endedAt
    earliestBySensor {
      temperature {
        bucketEndAt
        battery {
          temperature
        }
      }
      ecg {
        bucketEndAt
        battery {
          ecg
        }
      }
      spo2 {
        bucketEndAt
        battery {
          spo2
        }
      }
    }
    latestBySensor {
      temperature {
        bucketEndAt
        battery {
          temperature
        }
      }
      ecg {
        bucketEndAt
        battery {
          ecg
        }
      }
      spo2 {
        bucketEndAt
        battery {
          spo2
        }
      }
    }
  }
  wardAdmissionForOrganization(organizationId: $organizationId) {
    admittedAt
    ward {
      id
      name
    }
    carePathway {
      id
      name
    }
  }
}
    `;
export const VivalinkSensorItemFragmentDocInternal = gql`
    fragment VivalinkSensorItem on VivalinkSensor {
  sensorId
  sensorType
  lastActive
  topOne {
    recordTime
    patchBattery
  }
}
    `;
export const CreateCarePathwayDocumentInternal = gql`
    mutation CreateCarePathway($name: String!, $organizationId: ID, $templateId: ID, $published: Boolean!) {
  createCarePathway(
    name: $name
    organizationId: $organizationId
    templateId: $templateId
    published: $published
  ) {
    id
    name
    createdAt
    publishedAt
  }
}
    `;
export type CreateCarePathwayMutationFnInternal = Apollo.MutationFunction<CreateCarePathwayMutationInternal, CreateCarePathwayMutationVariablesInternal>;

/**
 * __useCreateCarePathwayMutationInternal__
 *
 * To run a mutation, you first call `useCreateCarePathwayMutationInternal` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarePathwayMutationInternal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarePathwayMutationInternal, { data, loading, error }] = useCreateCarePathwayMutationInternal({
 *   variables: {
 *      name: // value for 'name'
 *      organizationId: // value for 'organizationId'
 *      templateId: // value for 'templateId'
 *      published: // value for 'published'
 *   },
 * });
 */
export function useCreateCarePathwayMutationInternal(baseOptions?: Apollo.MutationHookOptions<CreateCarePathwayMutationInternal, CreateCarePathwayMutationVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCarePathwayMutationInternal, CreateCarePathwayMutationVariablesInternal>(CreateCarePathwayDocumentInternal, options);
      }
export type CreateCarePathwayMutationInternalHookResult = ReturnType<typeof useCreateCarePathwayMutationInternal>;
export type CreateCarePathwayMutationResultInternal = Apollo.MutationResult<CreateCarePathwayMutationInternal>;
export type CreateCarePathwayMutationOptionsInternal = Apollo.BaseMutationOptions<CreateCarePathwayMutationInternal, CreateCarePathwayMutationVariablesInternal>;
export const AddCheckupTypeDocumentInternal = gql`
    mutation AddCheckupType($pathwayId: ID!, $checkupType: CheckupTypeInput!) {
  addCheckupType(pathwayId: $pathwayId, checkupType: $checkupType) {
    id
    name
    createdAt
  }
}
    `;
export type AddCheckupTypeMutationFnInternal = Apollo.MutationFunction<AddCheckupTypeMutationInternal, AddCheckupTypeMutationVariablesInternal>;

/**
 * __useAddCheckupTypeMutationInternal__
 *
 * To run a mutation, you first call `useAddCheckupTypeMutationInternal` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCheckupTypeMutationInternal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCheckupTypeMutationInternal, { data, loading, error }] = useAddCheckupTypeMutationInternal({
 *   variables: {
 *      pathwayId: // value for 'pathwayId'
 *      checkupType: // value for 'checkupType'
 *   },
 * });
 */
export function useAddCheckupTypeMutationInternal(baseOptions?: Apollo.MutationHookOptions<AddCheckupTypeMutationInternal, AddCheckupTypeMutationVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCheckupTypeMutationInternal, AddCheckupTypeMutationVariablesInternal>(AddCheckupTypeDocumentInternal, options);
      }
export type AddCheckupTypeMutationInternalHookResult = ReturnType<typeof useAddCheckupTypeMutationInternal>;
export type AddCheckupTypeMutationResultInternal = Apollo.MutationResult<AddCheckupTypeMutationInternal>;
export type AddCheckupTypeMutationOptionsInternal = Apollo.BaseMutationOptions<AddCheckupTypeMutationInternal, AddCheckupTypeMutationVariablesInternal>;
export const GetAlertRulesDocumentInternal = gql`
    query GetAlertRules($carePathwayId: ID!) {
  alertRules(carePathwayId: $carePathwayId) {
    ...AdminAlertRulesItem
  }
}
    ${AdminAlertRulesItemFragmentDocInternal}`;

/**
 * __useGetAlertRulesQueryInternal__
 *
 * To run a query within a React component, call `useGetAlertRulesQueryInternal` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertRulesQueryInternal` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertRulesQueryInternal({
 *   variables: {
 *      carePathwayId: // value for 'carePathwayId'
 *   },
 * });
 */
export function useGetAlertRulesQueryInternal(baseOptions: Apollo.QueryHookOptions<GetAlertRulesQueryInternal, GetAlertRulesQueryVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlertRulesQueryInternal, GetAlertRulesQueryVariablesInternal>(GetAlertRulesDocumentInternal, options);
      }
export function useGetAlertRulesLazyQueryInternalInternal(baseOptions?: Apollo.LazyQueryHookOptions<GetAlertRulesQueryInternal, GetAlertRulesQueryVariablesInternal>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlertRulesQueryInternal, GetAlertRulesQueryVariablesInternal>(GetAlertRulesDocumentInternal, options);
        }
export type GetAlertRulesQueryInternalHookResult = ReturnType<typeof useGetAlertRulesQueryInternal>;
export type GetAlertRulesLazyQueryInternalInternalHookResult = ReturnType<typeof useGetAlertRulesLazyQueryInternalInternal>;
export type GetAlertRulesQueryResultInternal = Apollo.QueryResult<GetAlertRulesQueryInternal, GetAlertRulesQueryVariablesInternal>;
export const CarePathwayAdminDocumentInternal = gql`
    query CarePathwayAdmin($id: ID!) {
  carePathway(id: $id) {
    name
    publishedAt
    deletedAt
    ewsThresholds {
      thresholds
    }
    checkupTypes {
      ...AdminCheckupTypeItem
    }
    organization {
      id
    }
  }
}
    ${AdminCheckupTypeItemFragmentDocInternal}`;

/**
 * __useCarePathwayAdminQueryInternal__
 *
 * To run a query within a React component, call `useCarePathwayAdminQueryInternal` and pass it any options that fit your needs.
 * When your component renders, `useCarePathwayAdminQueryInternal` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarePathwayAdminQueryInternal({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCarePathwayAdminQueryInternal(baseOptions: Apollo.QueryHookOptions<CarePathwayAdminQueryInternal, CarePathwayAdminQueryVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CarePathwayAdminQueryInternal, CarePathwayAdminQueryVariablesInternal>(CarePathwayAdminDocumentInternal, options);
      }
export function useCarePathwayAdminLazyQueryInternalInternal(baseOptions?: Apollo.LazyQueryHookOptions<CarePathwayAdminQueryInternal, CarePathwayAdminQueryVariablesInternal>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CarePathwayAdminQueryInternal, CarePathwayAdminQueryVariablesInternal>(CarePathwayAdminDocumentInternal, options);
        }
export type CarePathwayAdminQueryInternalHookResult = ReturnType<typeof useCarePathwayAdminQueryInternal>;
export type CarePathwayAdminLazyQueryInternalInternalHookResult = ReturnType<typeof useCarePathwayAdminLazyQueryInternalInternal>;
export type CarePathwayAdminQueryResultInternal = Apollo.QueryResult<CarePathwayAdminQueryInternal, CarePathwayAdminQueryVariablesInternal>;
export const OrganizationCarePathwaysDocumentInternal = gql`
    query OrganizationCarePathways($organizationId: ID!, $includeDeleted: Boolean!) {
  carePathways(organizationId: $organizationId, includeDeleted: $includeDeleted) {
    ...AdminCarePathwayItem
  }
}
    ${AdminCarePathwayItemFragmentDocInternal}`;

/**
 * __useOrganizationCarePathwaysQueryInternal__
 *
 * To run a query within a React component, call `useOrganizationCarePathwaysQueryInternal` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCarePathwaysQueryInternal` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCarePathwaysQueryInternal({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useOrganizationCarePathwaysQueryInternal(baseOptions: Apollo.QueryHookOptions<OrganizationCarePathwaysQueryInternal, OrganizationCarePathwaysQueryVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationCarePathwaysQueryInternal, OrganizationCarePathwaysQueryVariablesInternal>(OrganizationCarePathwaysDocumentInternal, options);
      }
export function useOrganizationCarePathwaysLazyQueryInternalInternal(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationCarePathwaysQueryInternal, OrganizationCarePathwaysQueryVariablesInternal>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationCarePathwaysQueryInternal, OrganizationCarePathwaysQueryVariablesInternal>(OrganizationCarePathwaysDocumentInternal, options);
        }
export type OrganizationCarePathwaysQueryInternalHookResult = ReturnType<typeof useOrganizationCarePathwaysQueryInternal>;
export type OrganizationCarePathwaysLazyQueryInternalInternalHookResult = ReturnType<typeof useOrganizationCarePathwaysLazyQueryInternalInternal>;
export type OrganizationCarePathwaysQueryResultInternal = Apollo.QueryResult<OrganizationCarePathwaysQueryInternal, OrganizationCarePathwaysQueryVariablesInternal>;
export const OrganizationCarePathwayTemplatesDocumentInternal = gql`
    query OrganizationCarePathwayTemplates($organizationId: ID!) {
  carePathwayTemplates(organizationId: $organizationId) {
    ...AdminCarePathwayTemplateItem
  }
}
    ${AdminCarePathwayTemplateItemFragmentDocInternal}`;

/**
 * __useOrganizationCarePathwayTemplatesQueryInternal__
 *
 * To run a query within a React component, call `useOrganizationCarePathwayTemplatesQueryInternal` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCarePathwayTemplatesQueryInternal` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCarePathwayTemplatesQueryInternal({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationCarePathwayTemplatesQueryInternal(baseOptions: Apollo.QueryHookOptions<OrganizationCarePathwayTemplatesQueryInternal, OrganizationCarePathwayTemplatesQueryVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationCarePathwayTemplatesQueryInternal, OrganizationCarePathwayTemplatesQueryVariablesInternal>(OrganizationCarePathwayTemplatesDocumentInternal, options);
      }
export function useOrganizationCarePathwayTemplatesLazyQueryInternalInternal(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationCarePathwayTemplatesQueryInternal, OrganizationCarePathwayTemplatesQueryVariablesInternal>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationCarePathwayTemplatesQueryInternal, OrganizationCarePathwayTemplatesQueryVariablesInternal>(OrganizationCarePathwayTemplatesDocumentInternal, options);
        }
export type OrganizationCarePathwayTemplatesQueryInternalHookResult = ReturnType<typeof useOrganizationCarePathwayTemplatesQueryInternal>;
export type OrganizationCarePathwayTemplatesLazyQueryInternalInternalHookResult = ReturnType<typeof useOrganizationCarePathwayTemplatesLazyQueryInternalInternal>;
export type OrganizationCarePathwayTemplatesQueryResultInternal = Apollo.QueryResult<OrganizationCarePathwayTemplatesQueryInternal, OrganizationCarePathwayTemplatesQueryVariablesInternal>;
export const PreviewCheckupAlertRuleDocumentInternal = gql`
    mutation PreviewCheckupAlertRule($condition: String!, $organizationId: ID) {
  previewCheckupAlertRule(organizationId: $organizationId, condition: $condition) {
    condition
    percentTriggered
    numTested
    examples {
      id
      endedAt
      patient {
        id
      }
    }
  }
}
    `;
export type PreviewCheckupAlertRuleMutationFnInternal = Apollo.MutationFunction<PreviewCheckupAlertRuleMutationInternal, PreviewCheckupAlertRuleMutationVariablesInternal>;

/**
 * __usePreviewCheckupAlertRuleMutationInternal__
 *
 * To run a mutation, you first call `usePreviewCheckupAlertRuleMutationInternal` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewCheckupAlertRuleMutationInternal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewCheckupAlertRuleMutationInternal, { data, loading, error }] = usePreviewCheckupAlertRuleMutationInternal({
 *   variables: {
 *      condition: // value for 'condition'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function usePreviewCheckupAlertRuleMutationInternal(baseOptions?: Apollo.MutationHookOptions<PreviewCheckupAlertRuleMutationInternal, PreviewCheckupAlertRuleMutationVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreviewCheckupAlertRuleMutationInternal, PreviewCheckupAlertRuleMutationVariablesInternal>(PreviewCheckupAlertRuleDocumentInternal, options);
      }
export type PreviewCheckupAlertRuleMutationInternalHookResult = ReturnType<typeof usePreviewCheckupAlertRuleMutationInternal>;
export type PreviewCheckupAlertRuleMutationResultInternal = Apollo.MutationResult<PreviewCheckupAlertRuleMutationInternal>;
export type PreviewCheckupAlertRuleMutationOptionsInternal = Apollo.BaseMutationOptions<PreviewCheckupAlertRuleMutationInternal, PreviewCheckupAlertRuleMutationVariablesInternal>;
export const PreviewContinuousMonitoringAlertRuleDocumentInternal = gql`
    mutation PreviewContinuousMonitoringAlertRule($condition: String!, $organizationId: ID) {
  previewContinuousMonitoringAlertRule(
    organizationId: $organizationId
    condition: $condition
  ) {
    condition
    percentTriggered
    numTested
    examples {
      id
      bucketStartAt
      patient {
        id
      }
    }
  }
}
    `;
export type PreviewContinuousMonitoringAlertRuleMutationFnInternal = Apollo.MutationFunction<PreviewContinuousMonitoringAlertRuleMutationInternal, PreviewContinuousMonitoringAlertRuleMutationVariablesInternal>;

/**
 * __usePreviewContinuousMonitoringAlertRuleMutationInternal__
 *
 * To run a mutation, you first call `usePreviewContinuousMonitoringAlertRuleMutationInternal` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewContinuousMonitoringAlertRuleMutationInternal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewContinuousMonitoringAlertRuleMutationInternal, { data, loading, error }] = usePreviewContinuousMonitoringAlertRuleMutationInternal({
 *   variables: {
 *      condition: // value for 'condition'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function usePreviewContinuousMonitoringAlertRuleMutationInternal(baseOptions?: Apollo.MutationHookOptions<PreviewContinuousMonitoringAlertRuleMutationInternal, PreviewContinuousMonitoringAlertRuleMutationVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreviewContinuousMonitoringAlertRuleMutationInternal, PreviewContinuousMonitoringAlertRuleMutationVariablesInternal>(PreviewContinuousMonitoringAlertRuleDocumentInternal, options);
      }
export type PreviewContinuousMonitoringAlertRuleMutationInternalHookResult = ReturnType<typeof usePreviewContinuousMonitoringAlertRuleMutationInternal>;
export type PreviewContinuousMonitoringAlertRuleMutationResultInternal = Apollo.MutationResult<PreviewContinuousMonitoringAlertRuleMutationInternal>;
export type PreviewContinuousMonitoringAlertRuleMutationOptionsInternal = Apollo.BaseMutationOptions<PreviewContinuousMonitoringAlertRuleMutationInternal, PreviewContinuousMonitoringAlertRuleMutationVariablesInternal>;
export const UpdateAlertRuleDocumentInternal = gql`
    mutation UpdateAlertRule($id: ID!, $type: String!, $name: String!, $description: String!, $condition: String!) {
  updateAlertRule(
    id: $id
    type: $type
    name: $name
    description: $description
    condition: $condition
  ) {
    id
  }
}
    `;
export type UpdateAlertRuleMutationFnInternal = Apollo.MutationFunction<UpdateAlertRuleMutationInternal, UpdateAlertRuleMutationVariablesInternal>;

/**
 * __useUpdateAlertRuleMutationInternal__
 *
 * To run a mutation, you first call `useUpdateAlertRuleMutationInternal` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertRuleMutationInternal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertRuleMutationInternal, { data, loading, error }] = useUpdateAlertRuleMutationInternal({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useUpdateAlertRuleMutationInternal(baseOptions?: Apollo.MutationHookOptions<UpdateAlertRuleMutationInternal, UpdateAlertRuleMutationVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAlertRuleMutationInternal, UpdateAlertRuleMutationVariablesInternal>(UpdateAlertRuleDocumentInternal, options);
      }
export type UpdateAlertRuleMutationInternalHookResult = ReturnType<typeof useUpdateAlertRuleMutationInternal>;
export type UpdateAlertRuleMutationResultInternal = Apollo.MutationResult<UpdateAlertRuleMutationInternal>;
export type UpdateAlertRuleMutationOptionsInternal = Apollo.BaseMutationOptions<UpdateAlertRuleMutationInternal, UpdateAlertRuleMutationVariablesInternal>;
export const CreateAlertRuleDocumentInternal = gql`
    mutation CreateAlertRule($carePathwayId: ID!, $name: String!, $type: String!, $description: String!, $condition: String!) {
  createAlertRule(
    carePathwayId: $carePathwayId
    name: $name
    type: $type
    description: $description
    condition: $condition
  ) {
    id
  }
}
    `;
export type CreateAlertRuleMutationFnInternal = Apollo.MutationFunction<CreateAlertRuleMutationInternal, CreateAlertRuleMutationVariablesInternal>;

/**
 * __useCreateAlertRuleMutationInternal__
 *
 * To run a mutation, you first call `useCreateAlertRuleMutationInternal` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlertRuleMutationInternal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlertRuleMutationInternal, { data, loading, error }] = useCreateAlertRuleMutationInternal({
 *   variables: {
 *      carePathwayId: // value for 'carePathwayId'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      description: // value for 'description'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateAlertRuleMutationInternal(baseOptions?: Apollo.MutationHookOptions<CreateAlertRuleMutationInternal, CreateAlertRuleMutationVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAlertRuleMutationInternal, CreateAlertRuleMutationVariablesInternal>(CreateAlertRuleDocumentInternal, options);
      }
export type CreateAlertRuleMutationInternalHookResult = ReturnType<typeof useCreateAlertRuleMutationInternal>;
export type CreateAlertRuleMutationResultInternal = Apollo.MutationResult<CreateAlertRuleMutationInternal>;
export type CreateAlertRuleMutationOptionsInternal = Apollo.BaseMutationOptions<CreateAlertRuleMutationInternal, CreateAlertRuleMutationVariablesInternal>;
export const SetCarePathwayThresholdsDocumentInternal = gql`
    mutation SetCarePathwayThresholds($id: ID!, $thresholds: ThresholdsInput, $overwritePatients: Boolean!) {
  setCarePathwayThresholds(
    id: $id
    thresholds: $thresholds
    overwritePatients: $overwritePatients
  ) {
    id
  }
}
    `;
export type SetCarePathwayThresholdsMutationFnInternal = Apollo.MutationFunction<SetCarePathwayThresholdsMutationInternal, SetCarePathwayThresholdsMutationVariablesInternal>;

/**
 * __useSetCarePathwayThresholdsMutationInternal__
 *
 * To run a mutation, you first call `useSetCarePathwayThresholdsMutationInternal` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCarePathwayThresholdsMutationInternal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCarePathwayThresholdsMutationInternal, { data, loading, error }] = useSetCarePathwayThresholdsMutationInternal({
 *   variables: {
 *      id: // value for 'id'
 *      thresholds: // value for 'thresholds'
 *      overwritePatients: // value for 'overwritePatients'
 *   },
 * });
 */
export function useSetCarePathwayThresholdsMutationInternal(baseOptions?: Apollo.MutationHookOptions<SetCarePathwayThresholdsMutationInternal, SetCarePathwayThresholdsMutationVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCarePathwayThresholdsMutationInternal, SetCarePathwayThresholdsMutationVariablesInternal>(SetCarePathwayThresholdsDocumentInternal, options);
      }
export type SetCarePathwayThresholdsMutationInternalHookResult = ReturnType<typeof useSetCarePathwayThresholdsMutationInternal>;
export type SetCarePathwayThresholdsMutationResultInternal = Apollo.MutationResult<SetCarePathwayThresholdsMutationInternal>;
export type SetCarePathwayThresholdsMutationOptionsInternal = Apollo.BaseMutationOptions<SetCarePathwayThresholdsMutationInternal, SetCarePathwayThresholdsMutationVariablesInternal>;
export const UpdateCarePathwayDocumentInternal = gql`
    mutation UpdateCarePathway($id: ID!, $name: String!, $published: Boolean!) {
  updateCarePathway(id: $id, name: $name, published: $published) {
    id
    name
    createdAt
    publishedAt
  }
}
    `;
export type UpdateCarePathwayMutationFnInternal = Apollo.MutationFunction<UpdateCarePathwayMutationInternal, UpdateCarePathwayMutationVariablesInternal>;

/**
 * __useUpdateCarePathwayMutationInternal__
 *
 * To run a mutation, you first call `useUpdateCarePathwayMutationInternal` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarePathwayMutationInternal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarePathwayMutationInternal, { data, loading, error }] = useUpdateCarePathwayMutationInternal({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      published: // value for 'published'
 *   },
 * });
 */
export function useUpdateCarePathwayMutationInternal(baseOptions?: Apollo.MutationHookOptions<UpdateCarePathwayMutationInternal, UpdateCarePathwayMutationVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCarePathwayMutationInternal, UpdateCarePathwayMutationVariablesInternal>(UpdateCarePathwayDocumentInternal, options);
      }
export type UpdateCarePathwayMutationInternalHookResult = ReturnType<typeof useUpdateCarePathwayMutationInternal>;
export type UpdateCarePathwayMutationResultInternal = Apollo.MutationResult<UpdateCarePathwayMutationInternal>;
export type UpdateCarePathwayMutationOptionsInternal = Apollo.BaseMutationOptions<UpdateCarePathwayMutationInternal, UpdateCarePathwayMutationVariablesInternal>;
export const SetCheckupTypeScheduleDocumentInternal = gql`
    mutation setCheckupTypeSchedule($checkupTypeId: ID!, $schedule: CheckupScheduleInput!) {
  setCheckupTypeSchedule(checkupTypeId: $checkupTypeId, schedule: $schedule) {
    id
  }
}
    `;
export type SetCheckupTypeScheduleMutationFnInternal = Apollo.MutationFunction<SetCheckupTypeScheduleMutationInternal, SetCheckupTypeScheduleMutationVariablesInternal>;

/**
 * __useSetCheckupTypeScheduleMutationInternal__
 *
 * To run a mutation, you first call `useSetCheckupTypeScheduleMutationInternal` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCheckupTypeScheduleMutationInternal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCheckupTypeScheduleMutationInternal, { data, loading, error }] = useSetCheckupTypeScheduleMutationInternal({
 *   variables: {
 *      checkupTypeId: // value for 'checkupTypeId'
 *      schedule: // value for 'schedule'
 *   },
 * });
 */
export function useSetCheckupTypeScheduleMutationInternal(baseOptions?: Apollo.MutationHookOptions<SetCheckupTypeScheduleMutationInternal, SetCheckupTypeScheduleMutationVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCheckupTypeScheduleMutationInternal, SetCheckupTypeScheduleMutationVariablesInternal>(SetCheckupTypeScheduleDocumentInternal, options);
      }
export type SetCheckupTypeScheduleMutationInternalHookResult = ReturnType<typeof useSetCheckupTypeScheduleMutationInternal>;
export type SetCheckupTypeScheduleMutationResultInternal = Apollo.MutationResult<SetCheckupTypeScheduleMutationInternal>;
export type SetCheckupTypeScheduleMutationOptionsInternal = Apollo.BaseMutationOptions<SetCheckupTypeScheduleMutationInternal, SetCheckupTypeScheduleMutationVariablesInternal>;
export const UpdateCheckupTypeDocumentInternal = gql`
    mutation UpdateCheckupType($checkupTypeId: ID!, $checkupType: CheckupTypeUpdateInput!) {
  updateCheckupType(checkupTypeId: $checkupTypeId, checkupType: $checkupType) {
    id
    name
    createdAt
  }
}
    `;
export type UpdateCheckupTypeMutationFnInternal = Apollo.MutationFunction<UpdateCheckupTypeMutationInternal, UpdateCheckupTypeMutationVariablesInternal>;

/**
 * __useUpdateCheckupTypeMutationInternal__
 *
 * To run a mutation, you first call `useUpdateCheckupTypeMutationInternal` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckupTypeMutationInternal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheckupTypeMutationInternal, { data, loading, error }] = useUpdateCheckupTypeMutationInternal({
 *   variables: {
 *      checkupTypeId: // value for 'checkupTypeId'
 *      checkupType: // value for 'checkupType'
 *   },
 * });
 */
export function useUpdateCheckupTypeMutationInternal(baseOptions?: Apollo.MutationHookOptions<UpdateCheckupTypeMutationInternal, UpdateCheckupTypeMutationVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCheckupTypeMutationInternal, UpdateCheckupTypeMutationVariablesInternal>(UpdateCheckupTypeDocumentInternal, options);
      }
export type UpdateCheckupTypeMutationInternalHookResult = ReturnType<typeof useUpdateCheckupTypeMutationInternal>;
export type UpdateCheckupTypeMutationResultInternal = Apollo.MutationResult<UpdateCheckupTypeMutationInternal>;
export type UpdateCheckupTypeMutationOptionsInternal = Apollo.BaseMutationOptions<UpdateCheckupTypeMutationInternal, UpdateCheckupTypeMutationVariablesInternal>;
export const DeleteAlertRuleDocumentInternal = gql`
    mutation DeleteAlertRule($alertRuleId: ID!) {
  deleteAlertRule(id: $alertRuleId)
}
    `;
export type DeleteAlertRuleMutationFnInternal = Apollo.MutationFunction<DeleteAlertRuleMutationInternal, DeleteAlertRuleMutationVariablesInternal>;

/**
 * __useDeleteAlertRuleMutationInternal__
 *
 * To run a mutation, you first call `useDeleteAlertRuleMutationInternal` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertRuleMutationInternal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertRuleMutationInternal, { data, loading, error }] = useDeleteAlertRuleMutationInternal({
 *   variables: {
 *      alertRuleId: // value for 'alertRuleId'
 *   },
 * });
 */
export function useDeleteAlertRuleMutationInternal(baseOptions?: Apollo.MutationHookOptions<DeleteAlertRuleMutationInternal, DeleteAlertRuleMutationVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAlertRuleMutationInternal, DeleteAlertRuleMutationVariablesInternal>(DeleteAlertRuleDocumentInternal, options);
      }
export type DeleteAlertRuleMutationInternalHookResult = ReturnType<typeof useDeleteAlertRuleMutationInternal>;
export type DeleteAlertRuleMutationResultInternal = Apollo.MutationResult<DeleteAlertRuleMutationInternal>;
export type DeleteAlertRuleMutationOptionsInternal = Apollo.BaseMutationOptions<DeleteAlertRuleMutationInternal, DeleteAlertRuleMutationVariablesInternal>;
export const DeleteCheckupTypeDocumentInternal = gql`
    mutation DeleteCheckupType($checkupTypeId: ID!) {
  deleteCheckupType(checkupTypeId: $checkupTypeId)
}
    `;
export type DeleteCheckupTypeMutationFnInternal = Apollo.MutationFunction<DeleteCheckupTypeMutationInternal, DeleteCheckupTypeMutationVariablesInternal>;

/**
 * __useDeleteCheckupTypeMutationInternal__
 *
 * To run a mutation, you first call `useDeleteCheckupTypeMutationInternal` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCheckupTypeMutationInternal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCheckupTypeMutationInternal, { data, loading, error }] = useDeleteCheckupTypeMutationInternal({
 *   variables: {
 *      checkupTypeId: // value for 'checkupTypeId'
 *   },
 * });
 */
export function useDeleteCheckupTypeMutationInternal(baseOptions?: Apollo.MutationHookOptions<DeleteCheckupTypeMutationInternal, DeleteCheckupTypeMutationVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCheckupTypeMutationInternal, DeleteCheckupTypeMutationVariablesInternal>(DeleteCheckupTypeDocumentInternal, options);
      }
export type DeleteCheckupTypeMutationInternalHookResult = ReturnType<typeof useDeleteCheckupTypeMutationInternal>;
export type DeleteCheckupTypeMutationResultInternal = Apollo.MutationResult<DeleteCheckupTypeMutationInternal>;
export type DeleteCheckupTypeMutationOptionsInternal = Apollo.BaseMutationOptions<DeleteCheckupTypeMutationInternal, DeleteCheckupTypeMutationVariablesInternal>;
export const OrganizationAdminDocumentInternal = gql`
    query OrganizationAdmin($id: ID!) {
  organization(id: $id) {
    ...OrganizationAdminView
  }
}
    ${OrganizationAdminViewFragmentDocInternal}`;

/**
 * __useOrganizationAdminQueryInternal__
 *
 * To run a query within a React component, call `useOrganizationAdminQueryInternal` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAdminQueryInternal` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAdminQueryInternal({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationAdminQueryInternal(baseOptions: Apollo.QueryHookOptions<OrganizationAdminQueryInternal, OrganizationAdminQueryVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationAdminQueryInternal, OrganizationAdminQueryVariablesInternal>(OrganizationAdminDocumentInternal, options);
      }
export function useOrganizationAdminLazyQueryInternalInternal(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationAdminQueryInternal, OrganizationAdminQueryVariablesInternal>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationAdminQueryInternal, OrganizationAdminQueryVariablesInternal>(OrganizationAdminDocumentInternal, options);
        }
export type OrganizationAdminQueryInternalHookResult = ReturnType<typeof useOrganizationAdminQueryInternal>;
export type OrganizationAdminLazyQueryInternalInternalHookResult = ReturnType<typeof useOrganizationAdminLazyQueryInternalInternal>;
export type OrganizationAdminQueryResultInternal = Apollo.QueryResult<OrganizationAdminQueryInternal, OrganizationAdminQueryVariablesInternal>;
export const AdminDefaultFeaturesDocumentInternal = gql`
    query AdminDefaultFeatures {
  globalState(key: "default_features") {
    key
    value
  }
}
    `;

/**
 * __useAdminDefaultFeaturesQueryInternal__
 *
 * To run a query within a React component, call `useAdminDefaultFeaturesQueryInternal` and pass it any options that fit your needs.
 * When your component renders, `useAdminDefaultFeaturesQueryInternal` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDefaultFeaturesQueryInternal({
 *   variables: {
 *   },
 * });
 */
export function useAdminDefaultFeaturesQueryInternal(baseOptions?: Apollo.QueryHookOptions<AdminDefaultFeaturesQueryInternal, AdminDefaultFeaturesQueryVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminDefaultFeaturesQueryInternal, AdminDefaultFeaturesQueryVariablesInternal>(AdminDefaultFeaturesDocumentInternal, options);
      }
export function useAdminDefaultFeaturesLazyQueryInternalInternal(baseOptions?: Apollo.LazyQueryHookOptions<AdminDefaultFeaturesQueryInternal, AdminDefaultFeaturesQueryVariablesInternal>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminDefaultFeaturesQueryInternal, AdminDefaultFeaturesQueryVariablesInternal>(AdminDefaultFeaturesDocumentInternal, options);
        }
export type AdminDefaultFeaturesQueryInternalHookResult = ReturnType<typeof useAdminDefaultFeaturesQueryInternal>;
export type AdminDefaultFeaturesLazyQueryInternalInternalHookResult = ReturnType<typeof useAdminDefaultFeaturesLazyQueryInternalInternal>;
export type AdminDefaultFeaturesQueryResultInternal = Apollo.QueryResult<AdminDefaultFeaturesQueryInternal, AdminDefaultFeaturesQueryVariablesInternal>;
export const UpdateOrganizationFeaturesDocumentInternal = gql`
    mutation UpdateOrganizationFeatures($organizationId: ID!, $features: JSONObject!) {
  updateOrganization(organization: {id: $organizationId, features: $features}) {
    ...OrganizationAdminView
  }
}
    ${OrganizationAdminViewFragmentDocInternal}`;
export type UpdateOrganizationFeaturesMutationFnInternal = Apollo.MutationFunction<UpdateOrganizationFeaturesMutationInternal, UpdateOrganizationFeaturesMutationVariablesInternal>;

/**
 * __useUpdateOrganizationFeaturesMutationInternal__
 *
 * To run a mutation, you first call `useUpdateOrganizationFeaturesMutationInternal` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationFeaturesMutationInternal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationFeaturesMutationInternal, { data, loading, error }] = useUpdateOrganizationFeaturesMutationInternal({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      features: // value for 'features'
 *   },
 * });
 */
export function useUpdateOrganizationFeaturesMutationInternal(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationFeaturesMutationInternal, UpdateOrganizationFeaturesMutationVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationFeaturesMutationInternal, UpdateOrganizationFeaturesMutationVariablesInternal>(UpdateOrganizationFeaturesDocumentInternal, options);
      }
export type UpdateOrganizationFeaturesMutationInternalHookResult = ReturnType<typeof useUpdateOrganizationFeaturesMutationInternal>;
export type UpdateOrganizationFeaturesMutationResultInternal = Apollo.MutationResult<UpdateOrganizationFeaturesMutationInternal>;
export type UpdateOrganizationFeaturesMutationOptionsInternal = Apollo.BaseMutationOptions<UpdateOrganizationFeaturesMutationInternal, UpdateOrganizationFeaturesMutationVariablesInternal>;
export const SearchPatientsDocumentInternal = gql`
    query SearchPatients($organizationId: ID!, $query: String!, $includeDeleted: Boolean!) {
  searchPatients(
    organizationId: $organizationId
    query: $query
    includeDeleted: $includeDeleted
  ) {
    ...PatientSearchItem
  }
}
    ${PatientSearchItemFragmentDocInternal}`;

/**
 * __useSearchPatientsQueryInternal__
 *
 * To run a query within a React component, call `useSearchPatientsQueryInternal` and pass it any options that fit your needs.
 * When your component renders, `useSearchPatientsQueryInternal` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPatientsQueryInternal({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      query: // value for 'query'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useSearchPatientsQueryInternal(baseOptions: Apollo.QueryHookOptions<SearchPatientsQueryInternal, SearchPatientsQueryVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPatientsQueryInternal, SearchPatientsQueryVariablesInternal>(SearchPatientsDocumentInternal, options);
      }
export function useSearchPatientsLazyQueryInternalInternal(baseOptions?: Apollo.LazyQueryHookOptions<SearchPatientsQueryInternal, SearchPatientsQueryVariablesInternal>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPatientsQueryInternal, SearchPatientsQueryVariablesInternal>(SearchPatientsDocumentInternal, options);
        }
export type SearchPatientsQueryInternalHookResult = ReturnType<typeof useSearchPatientsQueryInternal>;
export type SearchPatientsLazyQueryInternalInternalHookResult = ReturnType<typeof useSearchPatientsLazyQueryInternalInternal>;
export type SearchPatientsQueryResultInternal = Apollo.QueryResult<SearchPatientsQueryInternal, SearchPatientsQueryVariablesInternal>;
export const VivalinkTroubleshootingDocumentInternal = gql`
    query VivalinkTroubleshooting($kitId: ID!) {
  vivalinkTroubleshooting(kitId: $kitId) {
    ...VivalinkSensorItem
  }
}
    ${VivalinkSensorItemFragmentDocInternal}`;

/**
 * __useVivalinkTroubleshootingQueryInternal__
 *
 * To run a query within a React component, call `useVivalinkTroubleshootingQueryInternal` and pass it any options that fit your needs.
 * When your component renders, `useVivalinkTroubleshootingQueryInternal` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVivalinkTroubleshootingQueryInternal({
 *   variables: {
 *      kitId: // value for 'kitId'
 *   },
 * });
 */
export function useVivalinkTroubleshootingQueryInternal(baseOptions: Apollo.QueryHookOptions<VivalinkTroubleshootingQueryInternal, VivalinkTroubleshootingQueryVariablesInternal>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VivalinkTroubleshootingQueryInternal, VivalinkTroubleshootingQueryVariablesInternal>(VivalinkTroubleshootingDocumentInternal, options);
      }
export function useVivalinkTroubleshootingLazyQueryInternalInternal(baseOptions?: Apollo.LazyQueryHookOptions<VivalinkTroubleshootingQueryInternal, VivalinkTroubleshootingQueryVariablesInternal>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VivalinkTroubleshootingQueryInternal, VivalinkTroubleshootingQueryVariablesInternal>(VivalinkTroubleshootingDocumentInternal, options);
        }
export type VivalinkTroubleshootingQueryInternalHookResult = ReturnType<typeof useVivalinkTroubleshootingQueryInternal>;
export type VivalinkTroubleshootingLazyQueryInternalInternalHookResult = ReturnType<typeof useVivalinkTroubleshootingLazyQueryInternalInternal>;
export type VivalinkTroubleshootingQueryResultInternal = Apollo.QueryResult<VivalinkTroubleshootingQueryInternal, VivalinkTroubleshootingQueryVariablesInternal>;